import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Tag,
  message,
  Pagination,
  Select,
  Modal,
  Radio,
  Space,
  Button,
  Popconfirm
} from "antd";
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import profileIcon1 from "../../Assets/images/singleuser.png";
import "./ManageAdmins.css";
import Instance from "../../AxiosConfig";
import { IoIosArrowDown } from "react-icons/io";

function ManageAdminsList() {
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    userId: null,
    fullName: '',
    email: '',
    role: ''
  });
  const [value, setValue] = useState(1);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await Instance.get("api/v1/roleAccess/roles", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
            "Content-Type": "application/json",
          },
        });

        // Filter out "ROLE_NORMAL" and remove duplicates based on role value
        const uniqueRoles = Array.from(
          new Map(
            response.data
              .filter(role => role.roleName !== 'ROLE_NORMAL')
              .map(role => [
                role.roleName,
                {
                  value: role.roleName,
                  label: role.roleName.replace(/_/g, ' ')
                }
              ])
          ).values()
        );

        setRoleOptions(uniqueRoles);
        console.log("ROLES: ", uniqueRoles);
        if (uniqueRoles.length > 0) {
          setSelectedRole(uniqueRoles[0].value);  // Set initial selected role
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);


  useEffect(() => {
    fetchUsersData();
  }, [pagination.current, pagination.pageSize]);

  const fetchUsersData = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/user/all`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        const filteredUsers = response.data.filter(
          (user) => user.roles[0]?.roleName !== "ROLE_NORMAL"
        );
        setData(filteredUsers);
        setPagination((prev) => ({
          ...prev,
          total: filteredUsers.length,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  const handleOpenAddPostModal = (user) => {
    setSelectedUser({
      userId: user.userId,
      fullName: user.fullName,
      email: user.email,
    });
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedUser({
      userId: null,
      fullName: '',
      email: '',
      role: ''
    });
  };

  const handleDelete = async (userId) => {
    try {
      const url = `/api/v1/admin/${userId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        fetchUsersData();
        message.success(response.data.message);
      }
    } catch (error) {
      console.log({ error });
      message.error({ content: "Failed to delete User" });
    }
  };

  const handleSaveRole = async (role) => {
    try {
      const url = `api/v1/admin/update/${selectedUser.userId}`;
      const response = await Instance.put(url, {
        roleName: role,
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        fetchUsersData();
        message.success("User role updated successfully");
        handleCloseModal();


      }
    } catch (error) {
      console.error("Failed to update user role:", error);
      message.error("Failed to update user role");
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };


  const filteredData = data.filter(
    (user) => user.roles[0]?.roleName !== "ROLE_NORMAL"
  );

  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  }


  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      render: (text, record) => (
        <div className="manage_admins_name_div">
          <div>
            <img
              className="manage_admins_image"
              src={
                record.imageData
                  ? `data:image/jpeg;base64,${record.imageData}`
                  : profileIcon1
              }
              alt="img"
            />
          </div>
          <div className="manage_admins_para">
            <p>{record.fullName}</p>
            <p>{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "MOBILE",
      dataIndex: "mobile",
    },
    {
      title: "USER ROLES",
      dataIndex: ["roles", 0, "roleName"],
      render: (roleName) => {
        if (!roleName) {
          return null; // or any default value you'd like to show when roleName is undefined
        }
        const roleDisplayName = roleName.replace(/_/g, ' ');
        const backgroundColor = stringToColor(roleName);
        const color = "#FFFFFF"; // Set a default text color that contrasts well with any background color

        return (
          <div
            style={{
              backgroundColor,
              color,
              padding: "4px 12px",
              borderRadius: "4px",
              width: "fit-content",
            }}
          >
            {roleDisplayName}
          </div>
        );
      },
    },

    {
      title: "ACTIONS",
      dataIndex: "actions",
      render: (text, record) => {
        return (
          <div className="delete_edit_icons">
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => handleDelete(record?.userId)}
              onCancel={() => message.info('User deletion aborted')}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine className="delete_icon" />
            </Popconfirm>
            <CiEdit
              className="edit_icon"
              onClick={() => handleOpenAddPostModal(record)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="container">
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={filteredData}
          rowKey={(record) => record.userId}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
            onChange: handleTableChange,
          }}
          loading={loading}
        />
      </div>
      <Modal
        title="Edit User Role"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button
            className="discard_button"
            key="cancel"
            onClick={() => setIsModalVisible(false)}
          >
            Discard
          </Button>,
          <Button
            className="save_changes_button"
            key="submit"
            type="primary"
            onClick={() => handleSaveRole(selectedRole)}
          >
            Save
          </Button>,
        ]}
      >
        {selectedUser && (
          <div>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div label="Full Name" name="name">
                  <Input
                    placeholder="Full Name"
                    value={selectedUser.fullName}
                    readOnly
                    className="form-control custom-placeholder"
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-3">
                <div label="Email" name="email">
                  <Input
                    placeholder="Email"
                    value={selectedUser.email}
                    readOnly
                    className="form-control custom-placeholder"
                  />
                </div>
              </div>
              <div
                className="col-lg-12 mb-3"
                style={{ textAlign: "left", width: "100%" }}
              >
                <Select
                  options={roleOptions}
                  value={selectedRole}
                  onChange={(value) => {
                    setSelectedRole(value);
                  }}
                  placeholder="Select a role"
                  style={{
                    width: '100%',
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ManageAdminsList;
