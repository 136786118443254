import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, Radio, Switch, message, Modal, Spin } from "antd";
import { DatePicker } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import product_4 from "../../Assets/images/Gallery.png";
import "./productCategories.css";
import Instance from "../../AxiosConfig";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { TimePicker } from "antd";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";

const bulkActions = [
  { value: "smallScale", label: "Small Scale" },
  { value: "largeScale", label: "Large Scale" },
];

export const EditProductss = ({
  isModalVisible,
  setIsModalVisible,
  selectedProduct,
}) => {
  console.log("selected product is", selectedProduct);

  const [selectedRadio, setSelectedRadio] = useState(1);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productSpecificationEnabled, setproductSpecificationEnabled] =
    useState(true);
  const [overview, setOverview] = useState("");
  const [features, setFeatures] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [applications, setApplications] = useState("");
  const [benefits, setBenefits] = useState("");
  const [usageInstructions, setUsageInstructions] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [cost, setCost] = useState("");
  const [quantityInStock, setQuantityInStock] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [returnPolicyEnabled, setReturnPolicyEnabled] = useState(false);
  const [scheduledStartDate, setScheduledStartDate] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [sections, setSections] = useState([
    { id: 1, section: "6MM", price: "", costprice: "" },
    { id: 2, section: "8MM", price: "", costprice: "" },
    { id: 3, section: "10MM", price: "", costprice: "" },
    { id: 4, section: "12MM", price: "", costprice: "" },
    { id: 5, section: "16MM", price: "", costprice: "" },
    { id: 6, section: "20MM", price: "", costprice: "" },
    { id: 7, section: "25MM", price: "", costprice: "" },
    { id: 8, section: "32MM", price: "", costprice: "" },
  ]);

  const [steelPrices, setSteelPrices] = useState({
    size6MMPrice: 0,
    size8MMPrice: 0,
    size10MMPrice: 0,
    size12MMPrice: 0,
    size16MMPrice: 0,
    size20MMPrice: 0,
    size25MMPrice: 0,
    size32MMPrice: 0,
    size6MMDiscountedPrice: 0,
    size8MMDiscountedPrice: 0,
    size10MMDiscountedPrice: 0,
    size12MMDiscountedPrice: 0,
    size16MMDiscountedPrice: 0,
    size20MMDiscountedPrice: 0,
    size25MMDiscountedPrice: 0,
    size32MMDiscountedPrice: 0,
  });
  const [mainImage, setMainImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [mainImageName, setMainImageName] = useState("");
  const [additionalImage1, setAdditionalImage1] = useState(null);
  const [additionalImage2, setAdditionalImage2] = useState(null);
  const [additionalImage3, setAdditionalImage3] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [additionalImage1Name, setAdditionalImage1Name] = useState("")
  const [additionalImage2Name, setAdditionalImage2Name] = useState("")
  const [additionalImage3Name, setAdditionalImage3Name] = useState("")
  const [newErrors, setNewErrors] = useState({ applications: null });
  const handleClose = () => {
    setIsModalVisible(false);
  };



  const stripHtmlTags = (input) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = input;
    return tempElement.textContent || tempElement.innerText || '';
  };


  const validateApplications = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Applications must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateSpecifications = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Specifications must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateBenefits = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Benefits must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateUsageInstructions = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Usage instructions must be between 250 and 2000 characters';
    }
    return null;
  };

  // Handlers to update state and validate fields

  const handleApplicationsChange = (value) => {
    setApplications(value);
    const applicationError = validateApplications(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      applications: applicationError,
    }));
  };

  const handleSpecificationsChange = (value) => {
    setSpecifications(value);
    const specificationsError = validateSpecifications(value);
    setErrors((prevErrors) => ({ ...prevErrors, specifications: specificationsError }));
  };

  const handleBenefitsChange = (value) => {
    setBenefits(value);
    const benefitsError = validateBenefits(value);
    setErrors((prevErrors) => ({ ...prevErrors, benefits: benefitsError }));
  };

  const handleUsageInstructionsChange = (value) => {
    setUsageInstructions(value);
    const usageInstructionsError = validateUsageInstructions(value);
    setErrors((prevErrors) => ({ ...prevErrors, usageInstructions: usageInstructionsError }));
  };

  // Form submit validation
  const validateField = (value, fieldName) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return `${fieldName} must be between 250 and 2000 characters`;
    }
    return null;
  };

  const handlePriceChange = (section, field, value) => {
    const parsedValue = parseFloat(value);
    if (parsedValue >= 0) {
      setSteelPrices((prevPrices) => ({
        ...prevPrices,
        [field]: parsedValue,
      }));
    }

    setSections((prevSections) =>
      prevSections.map((sec) =>
        sec.section === section
          ? {
            ...sec,
            [field.includes("Discounted") ? "costprice" : "price"]:
              parsedValue,
          }
          : sec
      )
    );
  };
  console.log("steelPrices:", steelPrices);
  // console.log('section:', section);

  const handlescheduledStartDateChange = (date) => {
    console.log(
      "Scheduled Date Changed:",
      date ? date.format("YYYY-MM-DD HH:mm:ss") : null
    );
    setScheduledStartDate(date ? date.format("YYYY-MM-DD HH:mm:ss") : null);
  };

  const handleReturnPolicy = () => {
    setReturnPolicyEnabled(!returnPolicyEnabled);
  };

  const handleproductSpecificationToggle = () => {
    setproductSpecificationEnabled(!productSpecificationEnabled);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };
  const [scheduledTime, setScheduledTime] = useState(null);

  const onChangeTime = (time, timeString) => {
    // console.log("Selected Time:", time, "Formatted Time:", timeString);
    setScheduledTime(timeString);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
  ];
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const brandOptions = brands.map((brand) => ({
    value: brand.brandName,
    label: brand.brandName,
  }));

  const Categoryoptions = categories.map((category) => ({
    value: category.categoryTitle,
    label: category.categoryTitle,
  }));

  const stripHtml = (html) => {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const getProductCategory = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
    setLoading(false);
  };

  const getBrandsByCategory = async (categoryName) => {
    if (!categoryName) {
      return;
    }
    setLoading(true);
    try {
      const url = `/api/v1/brand/${categoryName}`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        setBrands(response.data);
      } else {
        setBrands([]);
      }
    } catch (error) {
      console.error("Failed to fetch brands:", error);
      setBrands([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductCategory();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getBrandsByCategory(selectedCategory.value);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedProduct) {
      setProductName(selectedProduct.title);
      setSelectedCategory({
        value: selectedProduct.category.categoryTitle,
        label: selectedProduct.category.categoryTitle,
      });
      setSelectedBrand({
        value: selectedProduct.brand,
        label: selectedProduct.brand,
      });
      setSellingPrice(selectedProduct.discountedPrice);
      setCost(selectedProduct.price);
      setQuantityInStock(selectedProduct.quantity);
      setShortDescription(selectedProduct.description);
      setLongDescription(selectedProduct.longDescription);
      setOverview(selectedProduct.overview);
      setApplications(selectedProduct.applications);
      setSpecifications(selectedProduct.specifications);
      setFeatures(selectedProduct.features);
      setBenefits(selectedProduct.benefits);
      setUsageInstructions(selectedProduct.usageInstructions);
      setReturnPolicyEnabled(selectedProduct.returnPolicy === "yes");
      setSelectedRadio(selectedProduct.visibility === "Published" ? 1 : 2);
      setScheduledStartDate(selectedProduct.scheduledDate);

      setScheduledTime(selectedProduct.scheduledTime);
      setSteelPrices({
        size6MMPrice: selectedProduct.size6mmPrice || 0,
        size8MMPrice: selectedProduct.size8mmPrice || 0,
        size10MMPrice: selectedProduct.size10mmPrice || 0,
        size12MMPrice: selectedProduct.size12mmPrice || 0,
        size16MMPrice: selectedProduct.size16mmPrice || 0,
        size20MMPrice: selectedProduct.size20mmPrice || 0,
        size25MMPrice: selectedProduct.size25mmPrice || 0,
        size32MMPrice: selectedProduct.size32mmPrice || 0,
        size6MMDiscountedPrice: selectedProduct.size6mmDiscountedPrice || 0,
        size8MMDiscountedPrice: selectedProduct.size8mmDiscountedPrice || 0,
        size10MMDiscountedPrice: selectedProduct.size10mmDiscountedPrice || 0,
        size12MMDiscountedPrice: selectedProduct.size12mmDiscountedPrice || 0,
        size16MMDiscountedPrice: selectedProduct.size16mmDiscountedPrice || 0,
        size20MMDiscountedPrice: selectedProduct.size20mmDiscountedPrice || 0,
        size25MMDiscountedPrice: selectedProduct.size25mmDiscountedPrice || 0,
        size32MMDiscountedPrice: selectedProduct.size32mmDiscountedPrice || 0,
      });

      setMainImage(
        selectedProduct.imageData
          ? `data:image/jpeg;base64,${selectedProduct.imageData}`
          : null
      );
      setMainImageName(selectedProduct.imageUrl || "");

      setAdditionalImage1(
        selectedProduct.imageData1
          ? `data:image/jpeg;base64,${selectedProduct.imageData1}`
          : null
      );
      setAdditionalImage1Name(selectedProduct.imageUrl1 || "");

      setAdditionalImage2(
        selectedProduct.imageData2
          ? `data:image/jpeg;base64,${selectedProduct.imageData2}`
          : null
      );
      setAdditionalImage2Name(selectedProduct.imageUrl2 || "");

      setAdditionalImage3(
        selectedProduct.imageData3
          ? `data:image/jpeg;base64,${selectedProduct.imageData3}`
          : null
      );
      setAdditionalImage3Name(selectedProduct.imageUrl3 || "");

    }
  }, [selectedProduct]);



  const handlePreview = (image) => {
    setPreviewImage(image);
    setPreviewVisible(true);
  };
  const handleDelete = (setImage) => {
    setImage(null);
  };
  const handleMainImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setMainImage(e.target.result);
        setMainImageName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteMainImage = () => {
    console.log("Deleting main image...");
    setMainImage(null);
    setMainImageName("");
    console.log("Main image deleted. Current main image state:", mainImage);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleAdditionalImage1Change = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAdditionalImage1(e.target.result); // Store base64 string for the new image
      setAdditionalImage1Name(file.file.name); // Update the image name as well
    };
    reader.readAsDataURL(file.file); // Convert file to base64
  };

  const handleAdditionalImage2Change = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAdditionalImage2(e.target.result);
      setAdditionalImage2Name(file.file.name);
    };
    reader.readAsDataURL(file.file);
  };

  const handleAdditionalImage3Change = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setAdditionalImage3(e.target.result);
      setAdditionalImage3Name(file.file.name);
    };
    reader.readAsDataURL(file.file);
  };

  const base64ToBlob = (base64, type = "image/png") => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };

  const handleValidation = () => {
    const newErrors = {};
    if (!productName) newErrors.productName = "Product Name is required";
    if (!selectedCategory) newErrors.selectedCategory = "Category is required";
    if (!selectedBrand) newErrors.selectedBrand = "Brand is required";
    if (selectedCategory?.value?.toLowerCase() !== "steel") {
      if (!sellingPrice) newErrors.sellingPrice = "Selling Price is required";
      if (!cost) newErrors.cost = "Cost is required";
      if (cost && sellingPrice && parseFloat(cost) < parseFloat(sellingPrice)) {
        newErrors.cost = "Cost price cannot be smaller than Selling Price";
      }
      if (!quantityInStock) newErrors.quantityInStock = "Quantity in Stock is required";
    }
    if (
      !shortDescription ||
      shortDescription.length < 10 ||
      shortDescription.length > 40
    )
      newErrors.shortDescription =
        "Short Description must be between 10 and 40 characters";
    if (!longDescription || longDescription.length < 100 || longDescription.length > 1000) {
      newErrors.longDescription = "Long Description must be between 100 and 1000 characters";
    }
    if (!productSpecificationEnabled) { newErrors.productSpecificationEnabled = "Product Specifications must be enabled"; }


    if (!overview || overview.length < 250 || overview.length > 2000)
      newErrors.overview = "Overview must be between 250 and 2000 characters";

    if (!features || features.length < 250 || features.length > 2000)
      newErrors.features = "Features must be between 250 and 2000 characters";


    const applicationsError = validateField(applications, "Applications");
    if (applicationsError) {
      newErrors.applications = applicationsError;
    }

    // Validate benefits
    const benefitsError = validateField(benefits, "Benefits");
    if (benefitsError) {
      newErrors.benefits = benefitsError;
    }

    const specificationsError = validateField(specifications, "specifications");
    if (specificationsError) {
      newErrors.specifications = specificationsError;
    }

    // Validate usageInstructions
    const usageInstructionsError = validateField(usageInstructions, "Usage Instructions");
    if (usageInstructionsError) {
      newErrors.usageInstructions = usageInstructionsError;
    }
    //   if (applications && applications.length > 0) {
    //     if (applications.length < 250 || applications.length > 2000) {
    //         newErrors.applications = "Applications must be between 250 and 2000 characters";
    //     } else {
    //         newErrors.applications = null; // Set to null to indicate no error
    //     }
    // } else {
    //     newErrors.applications = null; // Set to null for empty field
    // }

    // if ((specifications.length !== 0) && (
    //   specifications.length < 250 ||
    //   specifications.length > 2000
    // ))
    //   newErrors.specifications =
    //     "Specifications must be between 250 and 2000 characters";
    // if ((benefits.length != 0) && (benefits.length < 250) || benefits.length > 2000)
    //   newErrors.benefits = "benefits must be between 250 and 2000 characters";
    // if ((usageInstructions.length !== 0) &&
    //   (usageInstructions.length < 250) ||
    //   usageInstructions.length > 2000
    // )
    //   newErrors.usageInstructions =
    //     "Usage instructions must be between 250 and 2000 characters";
    if (selectedRadio === 2 && !scheduledStartDate)
      newErrors.scheduledStartDate = "Scheduled Date is required";
    if (selectedRadio === 2 && !scheduledTime)
      newErrors.scheduledTime = "Scheduled Time is required";
    if (!mainImage) newErrors.mainImage = "At least one image must be uploaded";
    let hasSteelSize = false;
    sections.forEach((section) => {
      const price = steelPrices[`size${section.section}Price`];
      const costPrice = steelPrices[`size${section.section}DiscountedPrice`];
      console.log(`Section: ${section.section}, Price: ${price}, CostPrice: ${costPrice}`);

      if (costPrice > price) {
        newErrors[section.section] = `Cost price for ${section.section} cannot be greater than price`;
      }

      if (price !== null || costPrice !== null) {
        hasSteelSize = true;
      }
    });

    if (selectedCategory?.value?.toLowerCase() === "steel" && !hasSteelSize) {
      newErrors.steelSize = "At least one size must be provided for steel category";
    }
    setErrors(newErrors);
    const hasErrors = Object.keys(newErrors).length > 0;
    if (hasErrors) {
      message.error("Please fill the data correctly");
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setFormSubmitted(true);
    setLoading(true);
    const isValid = handleValidation();

    if (!isValid) {
      setLoading(false);
      return;
    }

    const { productId } = selectedProduct;
    const productDto = {
      title: productName,
      categoryTitle: selectedCategory.value,
      brand: selectedBrand.value,
      description: shortDescription,
      longDescription: stripHtml(longDescription),
      overview,
      features,
      specifications,
      applications,
      benefits,
      usageInstructions,
      price: parseFloat(cost),
      discountedPrice: parseFloat(sellingPrice),
      quantity: parseInt(quantityInStock),
      visibility: selectedRadio === 1 ? "Published" : "Scheduled",
      scheduledDate: selectedRadio === 2 ? scheduledStartDate : null,
      scheduledTime: selectedRadio === 2 ? scheduledTime : null,
      returnPolicy: returnPolicyEnabled ? "yes" : "no",
      size6mmPrice: steelPrices.size6MMPrice || null,
      size8mmPrice: steelPrices.size8MMPrice || null,
      size10mmPrice: steelPrices.size10MMPrice || null,
      size12mmPrice: steelPrices.size12MMPrice || null,
      size16mmPrice: steelPrices.size16MMPrice || null,
      size20mmPrice: steelPrices.size20MMPrice || null,
      size25mmPrice: steelPrices.size25MMPrice || null,
      size32mmPrice: steelPrices.size32MMPrice || null,
      size6mmDiscountedPrice: steelPrices.size6MMDiscountedPrice || null,
      size8mmDiscountedPrice: steelPrices.size8MMDiscountedPrice || null,
      size10mmDiscountedPrice: steelPrices.size10MMDiscountedPrice || null,
      size12mmDiscountedPrice: steelPrices.size12MMDiscountedPrice || null,
      size16mmDiscountedPrice: steelPrices.size16MMDiscountedPrice || null,
      size20mmDiscountedPrice: steelPrices.size20MMDiscountedPrice || null,
      size25mmDiscountedPrice: steelPrices.size25MMDiscountedPrice || null,
      size32mmDiscountedPrice: steelPrices.size32MMDiscountedPrice || null,
    };

    console.log("Product DTO being sent:", productDto);

    const formData = new FormData();
    formData.append("productDto", JSON.stringify(productDto));

    if (mainImage) {
      const base64MainImage = mainImage.split(",")[1];
      const mainImageBlob = base64ToBlob(base64MainImage, "image/png");
      formData.append("image", mainImageBlob, mainImageName);
    }
    if (additionalImage1) {
      const additionalImage1Blob = base64ToBlob(additionalImage1.split(",")[1], "image/png");
      formData.append("image1", additionalImage1Blob, additionalImage1Name);
    }

    if (additionalImage2) {
      const additionalImage2Blob = base64ToBlob(additionalImage2.split(",")[1], "image/png");
      formData.append("image2", additionalImage2Blob, additionalImage2Name);
    }

    if (additionalImage3) {
      const additionalImage3Blob = base64ToBlob(additionalImage3.split(",")[1], "image/png");
      formData.append("image3", additionalImage3Blob, additionalImage3Name);
    }

    console.log("Form Data being submitted:");
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}: ${value.name}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }
    try {
      const response = await Instance.put(
        `api/v1/products/update/${productId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          },
        }
      );

      // Log the response from the API
      console.log("API Response:", response);

      if (response.status === 200 || response.status === 201) {
        message.success("Product updated successfully");
        // Clear form data
        setProductName("");
        setSelectedCategory(null);
        setSelectedBrand(null);
        setSellingPrice("");
        setCost("");
        setSteelPrices({});
        setQuantityInStock("");
        setShortDescription("");
        setLongDescription("");
        setScheduledStartDate("");
        setScheduledTime(null);
        setPreviewImage(null);
        setMainImage(null);
        setAdditionalImage1(null);
        setAdditionalImage2(null);
        setAdditionalImage3(null);

        setErrors({});
        setIsModalVisible(false);
      } else {
        console.error("Failed to update product:", response.data);
        message.error("Failed to update product. Please try again.");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      message.error("An error occurred while updating the product.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const newErrors = { ...errors };

    if (formSubmitted) {
      if (!productName) {
        newErrors.productName = "Product Name is required";
      } else {
        delete newErrors.productName;
      }
    }
    if (formSubmitted) {
      if (!selectedCategory) {
        newErrors.selectedCategory = "Category is required";
      } else {
        delete newErrors.selectedCategory;
      }
    }
    if (formSubmitted) {
      if (!selectedBrand) {
        newErrors.selectedBrand = "Brand is required";
      } else {
        delete newErrors.selectedBrand;
      }
    }
    if (sellingPrice) {
      if (!sellingPrice) {
        newErrors.sellingPrice = "Selling price is required";
      } else {
        delete newErrors.sellingPrice;
      }
    }
    if (formSubmitted) {
      if (!cost) {
        newErrors.cost = "Cost is required";
      } else {
        delete newErrors.cost;
      }
    }
    if (formSubmitted) {
      if (!quantityInStock) {
        newErrors.quantityInStock = "Quantity is required";
      } else {
        delete newErrors.quantityInStock;
      }
    }
    if (formSubmitted) {
      if (!productSpecificationEnabled) {
        newErrors.productSpecificationEnabled =
          "Product Specifications must be enabled";
      } else {
        delete newErrors.productSpecificationEnabled;
      }
    }
    if (formSubmitted) {
      if (!mainImage) {
        newErrors.mainImage = "At least one must be uploaded";
      } else {
        delete newErrors.mainImage;
      }
    }
    if (formSubmitted) {
      if (selectedRadio === 2 && !scheduledStartDate) {
        newErrors.scheduledStartDate = "Scheduled Date is required";
      } else {
        delete newErrors.scheduledStartDate;
      }
    }
    if (formSubmitted) {
      if (selectedRadio === 2 && !scheduledTime) {
        newErrors.scheduledTime = "Scheduled Time is required";
      } else {
        delete newErrors.scheduledTime;
      }
    }
    if (!shortDescription || shortDescription.length < 10 || shortDescription.length > 40) {
      newErrors.shortDescription = "Short Description must be between 10 and 40 characters";
    } else {
      delete newErrors.shortDescription;
    }

    if (!longDescription || longDescription.length < 100 || longDescription.length > 1000) {
      newErrors.longDescription = "Long Description must be between 100 and 1000 characters";
    } else {
      delete newErrors.longDescription;
    }

    if (!overview || (overview.length < 250 || overview.length > 2000)) {
      newErrors.overview = "Overview must be between 250 and 2000 characters";
    } else {
      delete newErrors.overview;
    }

    if (!features || (features.length < 250 || features.length > 2000)) {
      newErrors.features = "Features must be between 250 and 2000 characters";
    } else {
      delete newErrors.features;
    }

    // if (specifications && (specifications.length < 250 || specifications.length > 2000)) {
    //   newErrors.specifications = "Specifications must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.specifications;
    // }

    //   if (applications && applications.length > 0) {
    //     if (applications.length < 250 || applications.length > 2000) {
    //         newErrors.applications = "Applications must be between 250 and 2000 characters";
    //     } else {
    //         newErrors.applications = null; 
    //     }
    // } else {
    //     newErrors.applications = null; 
    // }

    // if (benefits && (benefits.length < 250 || benefits.length > 2000)) {
    //   newErrors.benefits = "Benefits must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.benefits;
    // }

    // if (usageInstructions && (usageInstructions.length < 250 || usageInstructions.length > 2000)) {
    //   newErrors.usageInstructions = "Usage instructions must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.usageInstructions;
    // }



    setErrors(newErrors);
  }, [productName, selectedCategory, selectedBrand, sellingPrice, cost, quantityInStock, productSpecificationEnabled, mainImage, scheduledStartDate,
    scheduledTime, shortDescription, longDescription, overview, specifications, applications, benefits, usageInstructions, features]);
  return (
    <>
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        className="edit-product-model"
        footer={[null]}
        onCancel={() => {
          setIsModalVisible(false);
          setLongDescription("");
          setApplications("");
          setBenefits("");
          setFeatures("");
          setOverview("");
          setUsageInstructions("");
          setSpecifications("");
          setErrors({});
        }}
      >
        <div className="container">
          <div className="d-flex">
            <h5 className="add_product_heading">Edit Product</h5>
          </div>
          <div className="edit-product-div">
            <div className="col-lg-12 left_column">
              <div className="col-lg-12 mt-4 " style={{ textAlign: "left" }}>
                <label for="inputEmail4" className="form-label">
                  Product Name
                </label>
                <input
                  type="text"
                  className="form-control form_control"
                  placeholder="Cement"
                  id="inputEmail4"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                />
              </div>
              <div className="row mt-2">
                <div
                  className="col-lg-6 mt-3 search-table-container"
                  style={{ textAlign: "left" }}
                >
                  <label>Select Product Category</label>
                  <Select
                    options={Categoryoptions}
                    className="mt-2"
                    placeholder="Select Category"
                    styles={{ ...customStyles, menuPortal: document.body }}
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    required
                  />
                  {selectedCategory?.value?.toLowerCase() === "steel" && (
                    <div>
                      <h6 className="pt-2">Size</h6>
                      <div
                        className="card card-new-steel card-new-steels
               mb-2"
                      >
                        <div className="header-new-steel d-flex pb-2">
                          <p>Section</p>
                          <p>Cost&nbsp;Price</p>
                          <p>Selling&nbsp;Price</p>
                        </div>
                        {sections.map((section, index) => (
                          <div
                            key={section.id}
                            className="row row-new-steel d-flex"
                          >
                            <p>{section.section}</p>
                            <input
                              type="number"
                              min={0}
                              placeholder="₹"
                              value={
                                steelPrices[`size${section.section}Price`] ||
                                section.price
                              }
                              onChange={(e) =>
                                handlePriceChange(
                                  section.section,
                                  `size${section.section}Price`,
                                  e.target.value
                                )
                              }
                              style={{
                                border: "1px solid",
                                borderRadius: "10px",
                                padding: "2px 5px",
                                width: "100px",
                              }}
                            />
                            <input
                              type="number"
                              min={0}
                              placeholder="₹"
                              value={
                                steelPrices[
                                `size${section.section}DiscountedPrice`
                                ] || section.costprice
                              }
                              onChange={(e) =>
                                handlePriceChange(
                                  section.section,
                                  `size${section.section}DiscountedPrice`,
                                  e.target.value
                                )
                              }
                              style={{
                                border: "1px solid",
                                borderRadius: "10px",
                                padding: "2px 5px",
                                width: "100px",
                              }}
                            />
                            {errors[section.section] && (
                              <span className="error-message">
                                {errors[section.section]}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {errors.selectedCategory && (
                    <span className="error-message">
                      {errors.selectedCategory}
                    </span>
                  )}
                </div>
                <div
                  className="col-lg-6 mt-3 search-table-container"
                  style={{ textAlign: "left" }}
                >
                  <label>Select Brand</label>
                  <Select
                    options={selectedCategory ? brandOptions : []}
                    className="mt-2"
                    placeholder="select brand"
                    styles={{ ...customStyles, menuPortal: document.body }}
                    isDisabled={!selectedCategory}
                    value={selectedBrand}
                    onChange={(selectedOption) =>
                      setSelectedBrand(selectedOption.value)
                    }
                    required
                  />
                  {errors.selectedBrand && (
                    <span className="error-message">
                      {errors.selectedBrand}
                    </span>
                  )}
                  {selectedCategory?.value?.toLowerCase() === "steel" && (
                    <div className="mt-4 ">
                      <div className=" mt-4 " style={{ textAlign: "left" }}>
                        <label for="inputEmail4" className="form-label">
                          Cost
                        </label>
                        <input
                          type="text"
                          min={0}
                          className="form-control"
                          placeholder="&#8377;0"
                          id="inputEmail4"
                          value={cost}
                          onChange={(e) => setCost(e.target.value)}
                          // style={{width:"300px"}}
                          required
                        />
                        {errors.cost && (
                          <span className="error-message">{errors.cost}</span>
                        )}
                      </div>
                      <div className=" mt-4 " style={{ textAlign: "left" }}>
                        <label for="inputEmail4" className="form-label">
                          Selling Price
                        </label>
                        <input
                          type="text"
                          min={0}
                          className="form-control form_control"
                          placeholder="&#8377;0"
                          id="inputEmail4"
                          value={sellingPrice}
                          // style={{width:"300px"}}
                          onChange={(e) => setSellingPrice(e.target.value)}
                          required
                        />
                        {errors.sellingPrice && (
                          <span className="error-message">
                            {errors.sellingPrice}
                          </span>
                        )}
                      </div>
                      <div className=" mt-4 " style={{ textAlign: "left" }}>
                        <label for="inputEmail4" className="form-label">
                          Quantity in Stock
                        </label>
                        <input
                          type="number"
                          className="form-control form_control"
                          placeholder="Enter quantity"
                          id="inputEmail4"
                          value={quantityInStock}
                          onChange={(e) => setQuantityInStock(e.target.value)}
                          required
                        />
                        {errors.quantityInStock && (
                          <span className="error-message">
                            {errors.quantityInStock}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedCategory?.value?.toLowerCase() !== "steel" && (
                <div className="row ">
                  <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Selling Price
                    </label>
                    <input
                      type="text"
                      className="form-control form_control"
                      placeholder="&#8377;"
                      id="inputEmail4"
                      value={sellingPrice}
                      onChange={(e) => setSellingPrice(e.target.value)}
                      required
                    />
                    {errors.sellingPrice && (
                      <span className="error-message">
                        {errors.sellingPrice}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Cost
                    </label>
                    <input
                      type="text"
                      className="form-control form_control"
                      placeholder="&#8377;"
                      id="inputEmail4"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                      required
                    />
                    {errors.cost && (
                      <span className="error-message">{errors.cost}</span>
                    )}
                  </div>
                  <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Quantity in Stock
                    </label>
                    <input
                      type="number"
                      className="form-control form_control"
                      placeholder="100"
                      id="inputEmail4"
                      value={quantityInStock}
                      onChange={(e) => setQuantityInStock(e.target.value)}
                      required
                    />
                    {errors.quantityInStock && (
                      <span className="error-message">
                        {errors.quantityInStock}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <p className="product_description_heading">
                    Short Description
                  </p>
                  <Input.TextArea
                    rows={8}
                    placeholder="Short Description"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    required
                  />
                  {errors.shortDescription && (
                    <span className="error-message">
                      {errors.shortDescription}
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <p className="product_description_heading">
                    Product Long Description
                  </p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={longDescription}
                        onChange={setLongDescription}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.longDescription && (
                    <span className="error-message">
                      {errors.longDescription}
                    </span>
                  )}
                </div>
                <p className="long_description_p">
                  Add a long description for your product
                </p>
              </div>
            </div>
            <div className="add_voucher mt-4 col-lg-12">
              <div>
                <p className="gray_text_p">Product Specifications</p>
              </div>
              <div>
                {/* <span className="m-2">Specifications</span> */}
                <Switch
                  className="switch-input"
                  checked={productSpecificationEnabled}
                  onChange={handleproductSpecificationToggle}
                />
              </div>
            </div>
            {errors.productSpecificationEnabled && (
              <span className="error error-add-product mt-0">
                {errors.productSpecificationEnabled}
              </span>
            )}
            {productSpecificationEnabled && (
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="product_description_heading">Overview</p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={overview}
                          onChange={setOverview}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.overview && (
                      <span className="error-message">{errors.overview}</span>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <p className="product_description_heading">Features</p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={features}
                          onChange={setFeatures}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.features && (
                      <span className="error-message">{errors.features}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="product_description_heading">
                      Specifications
                    </p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={specifications}
                          onChange={handleSpecificationsChange}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.specifications && (
                      <span className="error-message">
                        {errors.specifications}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <p className="product_description_heading">Applications</p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={applications}
                          onChange={handleApplicationsChange}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.applications && (
                      <span className="error-message">
                        {errors.applications}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="product_description_heading">Benefits</p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={benefits}
                          onChange={handleBenefitsChange}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.benefits && (
                      <span className="error-message">{errors.benefits}</span>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <p className="product_description_heading">
                      Usage instructions
                    </p>
                    <div className="react-quill-wrapper">
                      <div className="react-quill-toolbar"></div>
                      <div className="react-quill-container">
                        <ReactQuill
                          value={usageInstructions}
                          onChange={handleUsageInstructionsChange}
                          placeholder="Your text goes here"
                          modules={modules}
                          formats={formats}
                          className="edit-reactquill"
                        />
                      </div>
                    </div>
                    {errors.usageInstructions && (
                      <span className="error-message">
                        {errors.usageInstructions}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="add_voucher mt-2 col-lg-12">
              <div>
                <p className="gray_text_p">Return Policy</p>
              </div>
              <div>
                <span className="m-2"></span>
                <Switch
                  className="switch-input"
                  checked={returnPolicyEnabled}
                  onChange={handleReturnPolicy}
                />
              </div>
            </div>
            <div className="col-lg-12 visibility">
              <p className="visibility_header">Visibility</p>
              <Radio.Group
                className="add_coupon_radio"
                onChange={handleRadioChange}
                value={selectedRadio}
                required
              >
                <Radio value={1}>Published</Radio>
                <Radio value={2}>Scheduled</Radio>
              </Radio.Group>
            </div>
            {selectedRadio === 2 && (
              <>
                <p className="date_added__heding mt-2">Date added</p>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <DatePicker
                      value={
                        scheduledStartDate
                          ? moment(scheduledStartDate, "YYYY-MM-DD HH:mm:ss")
                          : null
                      }
                      className="custom-date-picker"
                      onChange={handlescheduledStartDateChange}
                      placeholder="Start date"
                    />
                    {errors.scheduledStartDate && (
                      <span className="error-message">
                        {errors.scheduledStartDate}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 mb-3">
                    <TimePicker
                      value={
                        scheduledTime ? moment(scheduledTime, "HH:mm:ss") : null
                      }
                      onChange={onChangeTime}
                      className="custom-date-picker"
                    />{" "}
                    {errors.scheduledTime && (
                      <span className="error-message">
                        {errors.scheduledTime}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <h6 className="mt-3">Add Main Image</h6>
              <div className="add_image_div mt-4">
                <div className="add_image_icons">
                  <label htmlFor="uploadInput">
                    <FiUploadCloud className="add_image_upload_icon" />
                  </label>
                  <input
                    type="file"
                    id="uploadInput"
                    style={{ display: "none" }}
                    onChange={(e) => handleMainImageChange(e.target.files[0])}
                  />
                  {mainImage && (
                    <AiOutlineDelete
                      className="add_image_delete_icon"
                      onClick={handleDeleteMainImage}
                    />
                  )}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={mainImage ? mainImage : product_4}
                    className="image_1"
                    alt="Preview"
                    style={{ width: "200px", height: "200px", margin: "10px" }}
                  />
                </div>
              </div>
              {errors.mainImage && <span className="error-message">{errors.mainImage}</span>}

            </div>

            <div className="col-lg-6">
              <h6 className="mt-3">Additional Images</h6>

              <div className="d-flex gap-2">
                {/* Image 1 */}
                <Upload
                  listType="picture-card"
                  showUploadList={false} // Don't show the default list, handle manually
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleAdditionalImage1Change}
                >
                  {additionalImage1 ? (
                    <div className="edit-product-image-wrapper">
                      <img
                        src={additionalImage1}
                        alt="Image 1"
                        style={{ width: "100%" }}
                        className="edit-product-image"
                      />
                      <div className="edit-product-overlay"></div>{" "}
                      <div className="edit-product-hover-icons">
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(additionalImage1);
                          }}
                        >
                          <EyeOutlined className="edit-product-preview-icon" />
                        </div>
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(setAdditionalImage1);
                          }}
                        >
                          <DeleteOutlined className="edit-product-delete-icon" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    uploadButton
                  )}
                </Upload>

                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleAdditionalImage2Change}
                >
                  {additionalImage2 ? (
                    <div className="edit-product-image-wrapper">
                      <img
                        src={additionalImage2}
                        alt="Image 2"
                        style={{ width: "100%" }}
                        className="edit-product-image"
                      />
                      <div className="edit-product-overlay"></div>
                      <div className="edit-product-hover-icons">
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(additionalImage2);
                          }}
                        >
                          <EyeOutlined className="edit-product-preview-icon" />
                        </div>
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(setAdditionalImage2);
                          }}
                        >
                          <DeleteOutlined className="edit-product-delete-icon" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    uploadButton
                  )}
                </Upload>

                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleAdditionalImage3Change}
                >
                  {additionalImage3 ? (
                    <div className="edit-product-image-wrapper">
                      <img
                        src={additionalImage3}
                        alt="Image 3"
                        style={{ width: "100%" }}
                        className="edit-product-image"
                      />
                      <div className="edit-product-overlay"></div>
                      <div className="edit-product-hover-icons">
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(additionalImage3);
                          }}
                        >
                          <EyeOutlined className="edit-product-preview-icon" />
                        </div>
                        <div
                          className="icon-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(setAdditionalImage3);
                          }}
                        >
                          <DeleteOutlined className="edit-product-delete-icon" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <Modal
                visible={previewVisible}
                title="Image Preview"
                footer={null}
                onCancel={() => setPreviewVisible(false)}
              >
                <img
                  alt="Preview"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </div>

          <div className="last_row_buttons_div mt-4">
            <button
              onClick={handleClose}
              className="add_product_discard_button"
            >
              Discard
            </button>
            <button
              className="add_product_save_publish_button"
              onClick={handleSubmit}
            >
              {loading ? <Spin size="small" /> : "Save & Publish"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
