import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, Radio, Spin, Switch, message } from "antd";
import { DatePicker, Checkbox } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import product_4 from "../../Assets/images/Gallery.png";
import "./AddProduct.css";
import Instance from "../../AxiosConfig";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { TimePicker } from "antd";
import moment from "moment";

const AddProduct = () => {
  const [voucherEnabled, setVoucherEnabled] = useState(false);
  const [
    productSpecificationEnabled,
    setproductSpecificationEnabled,
  ] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [cost, setCost] = useState("");
  const [quantityInStock, setQuantityInStock] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [overview, setOverview] = useState("");
  const [features, setFeatures] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [applications, setApplications] = useState("");
  const [benefits, setBenefits] = useState("");
  const [usageInstructions, setUsageInstructions] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [status, setstatus] = useState("");
  const [registeredOnly, setregisteredOnly] = useState(false);
  const [couponStartDate, setCouponStartDate] = useState(null);
  const [couponEndDate, setCouponEndDate] = useState(null);
  const [returnPolicyEnabled, setReturnPolicyEnabled] = useState(false);
  const [scheduledStartDate, setScheduledStartDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormResetting, setIsFormResetting] = useState(false);
  const [sections, setSections] = useState([
    { id: 1, section: "6MM", price: "", costprice: "" },
    { id: 2, section: "8MM", price: "", costprice: "" },
    { id: 3, section: "10MM", price: "", costprice: "" },
    { id: 4, section: "12MM", price: "", costprice: "" },
    { id: 5, section: "16MM", price: "", costprice: "" },
    { id: 6, section: "20MM", price: "", costprice: "" },
    { id: 7, section: "25MM", price: "", costprice: "" },
    { id: 8, section: "32MM", price: "", costprice: "" },
  ]);

  const [steelPrices, setSteelPrices] = useState({
    size6MMPrice: 0,
    size8MMPrice: 0,
    size10MMPrice: 0,
    size12MMPrice: 0,
    size16MMPrice: 0,
    size20MMPrice: 0,
    size25MMPrice: 0,
    size32MMPrice: 0,
    size6MMDiscountedPrice: 0,
    size8MMDiscountedPrice: 0,
    size10MMDiscountedPrice: 0,
    size12MMDiscountedPrice: 0,
    size16MMDiscountedPrice: 0,
    size20MMDiscountedPrice: 0,
    size25MMDiscountedPrice: 0,
    size32MMDiscountedPrice: 0,
  });



  const stripHtmlTags = (input) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = input;
    return tempElement.textContent || tempElement.innerText || '';
  };


  const validateApplications = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Applications must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateSpecifications = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Specifications must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateBenefits = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Benefits must be between 250 and 2000 characters';
    }
    return null;
  };

  const validateUsageInstructions = (value) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return 'Usage instructions must be between 250 and 2000 characters';
    }
    return null;
  };

  // Handlers to update state and validate fields

  const handleApplicationsChange = (value) => {
    setApplications(value);
    const applicationError = validateApplications(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      applications: applicationError,
    }));
  };

  const handleSpecificationsChange = (value) => {
    setSpecifications(value);
    const specificationsError = validateSpecifications(value);
    setErrors((prevErrors) => ({ ...prevErrors, specifications: specificationsError }));
  };

  const handleBenefitsChange = (value) => {
    setBenefits(value);
    const benefitsError = validateBenefits(value);
    setErrors((prevErrors) => ({ ...prevErrors, benefits: benefitsError }));
  };

  const handleUsageInstructionsChange = (value) => {
    setUsageInstructions(value);
    const usageInstructionsError = validateUsageInstructions(value);
    setErrors((prevErrors) => ({ ...prevErrors, usageInstructions: usageInstructionsError }));
  };



  const validateField = (value, fieldName) => {
    const strippedValue = stripHtmlTags(value);
    if (strippedValue.length > 0 && (strippedValue.length < 250 || strippedValue.length > 2000)) {
      return `${fieldName} must be between 250 and 2000 characters`;
    }
    return null;
  };

  const handlePriceChange = (section, field, value) => {
    const parsedValue = value === "" ? null : parseFloat(value); // Handle empty input
    setSteelPrices((prevPrices) => ({
      ...prevPrices,
      [field]: parsedValue,
    }));

    setSections((prevSections) =>
      prevSections.map((sec) =>
        sec.section === section
          ? {
            ...sec,
            [field.includes("Discounted")
              ? "costprice"
              : "price"]: parsedValue,
          }
          : sec
      )
    );
  };

  const handleVoucherToggle = () => {
    setVoucherEnabled(!voucherEnabled);
  };

  const handleproductSpecificationToggle = () => {
    setproductSpecificationEnabled(!productSpecificationEnabled);
  };

  const handleReturnPolicy = () => {
    setReturnPolicyEnabled(!returnPolicyEnabled);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };
  const [scheduledTime, setScheduledTime] = useState(null);

  const onChangeTime = (time, timeString) => {
    console.log("Selected Time:", time, "Formatted Time:", timeString);
    setScheduledTime(timeString);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
  ];
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const previewImageFileObj = {
        uid: "main-image-uid",
        name: file.name,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        webkitRelativePath: "",
        size: file.size,
        type: file.type,
        originFileObj: file,
      };
      setMainImg(previewImageFileObj);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setMainImg(null);
  };
  const stripHtml = (html) => {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const getProductCategory = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Categories fetched:", response.data);
        setCategories(response.data); // Store the fetched categories in state
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
    setLoading(false);
  };

  const getBrandsByCategory = async (categoryName) => {
    if (!categoryName) {
      console.error("Category name is undefined");
      return;
    }
    setLoading(true);
    try {
      const url = `/api/v1/brand/${categoryName}`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Brands fetched:", response.data);
        setBrands(response.data);
      } else {
        setBrands([]);
      }
    } catch (error) {
      console.error("Failed to fetch brands:", error);
      setBrands([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductCategory();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      console.log("Selected category:", selectedCategory.value);
      getBrandsByCategory(selectedCategory.value);
    }
  }, [selectedCategory]);

  const handleCategoryChange = (selectedOption) => {
    console.log("Category selected:", selectedOption);
    setSelectedCategory(selectedOption);
  };

  const brandOptions = brands.map((brand) => ({
    value: brand.brandName,
    label: brand.brandName,
  }));

  const Categoryoptions = categories.map((category) => ({
    value: category.categoryTitle,
    label: category.categoryTitle,
  }));
  const ENABLED = "enabled";
  const DISABLED = "disabled";
  const PERCENTAGE = "PERCENTAGE";
  const DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT";
  const FREE_SHIPPING = "FREE_SHIPPING";

  const handleStartDateChange = (date) => {
    console.log(
      "Start Date Changed:",
      date ? date.format("YYYY-MM-DD HH:mm:ss") : null
    );
    setCouponStartDate(date ? date.format("YYYY-MM-DD HH:mm:ss") : null);
  };

  const handleEndDateChange = (date) => {
    console.log(
      "End Date Changed:",
      date ? date.format("YYYY-MM-DD HH:mm:ss") : null
    );
    setCouponEndDate(date ? date.format("YYYY-MM-DD HH:mm:ss") : null);
  };

  const handlescheduledStartDateChange = (date) => {
    console.log(
      "Scheduled Date Changed:",
      date ? date.format("YYYY-MM-DD HH:mm:ss") : null
    );
    setScheduledStartDate(date ? date.format("YYYY-MM-DD HH:mm:ss") : null);
  };

  const handleValueChange = (value) => {
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10); 
  
      if (value === '') {
        setCouponValue('');  
        return;
      }
  
      if (couponType === "PERCENTAGE") {
        if (numericValue < 1 || numericValue > 100) {
          message.error('Percentage must be between 1 and 100');
          return;
        }
      }
  
      setCouponValue(numericValue); 
    }
  };

  const handleValidation = () => {
    const newErrors = {};
    if (!productName) newErrors.productName = "Product Name is required";
    if (!selectedCategory) newErrors.selectedCategory = "Category is required";
    if (!selectedBrand) newErrors.selectedBrand = "Brand is required";
    if (selectedCategory?.value?.toLowerCase() !== "steel") {
      if (!sellingPrice) newErrors.sellingPrice = "Selling Price is required";
      if (!cost) newErrors.cost = "Cost is required";
      if (cost && sellingPrice && parseFloat(cost) < parseFloat(sellingPrice)) {
        newErrors.cost = "Cost price cannot be smaller than Selling Price";
      }
      if (!quantityInStock)
        newErrors.quantityInStock = "Quantity in Stock is required";
    }
    if (
      !shortDescription ||
      shortDescription.length < 10 ||
      shortDescription.length > 40
    )
      newErrors.shortDescription =
        "Short Description must be between 10 and 40 characters";
    if (
      !longDescription || longDescription.length < 100 ||
      longDescription.length > 1000
    )
      newErrors.longDescription =
        "Description must be between 100 and 1000 characters";



    if (!productSpecificationEnabled) {
      newErrors.productSpecificationEnabled =
        "Product Specifications must be enabled";
    }
    if ((!overview && overview.length < 250) || overview.length > 2000)
      newErrors.overview = "Overview must be between 250 and 2000 characters";

    if ((!features && features.length < 250) || features.length > 2000)
      newErrors.features = "Features must be between 250 and 2000 characters";

    const applicationsError = validateField(applications, "Applications");
  if (applicationsError) {
    newErrors.applications = applicationsError;
  }

  

    const specificationsError = validateField(specifications, "specifications");
    if (specificationsError) {
      newErrors.specifications = specificationsError;
    }


    const benefitsError = validateField(benefits, "Benefits");
    if (benefitsError) {
      newErrors.benefits = benefitsError;
    }


    const usageInstructionsError = validateField(usageInstructions, "Usage Instructions");
    if (usageInstructionsError) {
      newErrors.usageInstructions = usageInstructionsError;
    }



    if (voucherEnabled) {
      if (!couponCode) {
        newErrors.couponCode = "Coupon Code is required";
      }
      if (
        (couponType === "PERCENTAGE" || couponType === "DISCOUNT_AMOUNT") &&
        !couponValue
      ) {
        newErrors.couponValue = "Coupon Value is required";
      }
      if (!status) newErrors.status = "status must be choosen";
      if (!couponType) newErrors.couponType = "CouponType must be choosen";
      // if (!registeredOnly)
      //   newErrors.registeredOnly = "Checkbox must be selected";
      if (!couponStartDate)
        newErrors.couponStartDate = "Coupon Start Date is required";
      if (!couponEndDate)
        newErrors.couponEndDate = "Coupon End Date is required";
    }
    if (selectedRadio === 2 && !scheduledStartDate)
      newErrors.scheduledStartDate = "Scheduled Date is required";
    if (selectedRadio === 2 && !scheduledTime)
      newErrors.scheduledTime = "Scheduled Time is required";
    if (!mainImg && fileList.length === 0)
      newErrors.mainImg = "At least one image must be uploaded";

    let hasSteelSize = false;
    sections.forEach((section) => {
      const price = steelPrices[`size${section.section}Price`];
      const costPrice = steelPrices[`size${section.section}DiscountedPrice`];
      console.log(
        `Section: ${section.section}, Price: ${price}, CostPrice: ${costPrice}`
      );

      if (costPrice > price) {
        newErrors[
          section.section
        ] = `Cost price for ${section.section} cannot be greater than price`;
      }

      if (price !== null || costPrice !== null) {
        hasSteelSize = true;
      }
    });

    if (cost && sellingPrice && parseFloat(cost) < parseFloat(sellingPrice)) {
      newErrors.cost = "Cost price cannot be smaller than Selling Price";
    }

    if (selectedCategory?.value?.toLowerCase() === "steel" && !hasSteelSize) {
      newErrors.steelSize =
        "At least one size must be provided for steel category";
    }

    console.log("hasSteelSize:", hasSteelSize);
    // message.error("Fill the form dtails correctly",newErrors[1]);
    console.log(newErrors);
    const hasErrors = Object.keys(newErrors).length > 0;
    if (hasErrors) {
      message.error("Please fill the data correctly");
    } setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setFormSubmitted(true); 
    setLoading(true);
    const isValid = handleValidation();

    if (!isValid) {
      setLoading(false);
      return;
    }

    try {
      const url = "api/v1/products/add";
      const productDto = {
        title: productName,
        categoryTitle: selectedCategory.value,
        brand: selectedBrand,
        description: shortDescription,
        longDescription: stripHtml(longDescription),
        overview: overview,
        features: features,
        specifications: specifications,
        applications: applications,
        benefits: benefits,
        usageInstructions: usageInstructions,
        price: parseFloat(cost),
        discountedPrice: parseFloat(sellingPrice),
        quantity: parseInt(quantityInStock),
        couponCode: voucherEnabled ? couponCode : null,
        couponType: voucherEnabled ? couponType : null,
        couponValue: voucherEnabled ? parseFloat(couponValue) : null,
        status: voucherEnabled ? status : null,
        registeredOnly: voucherEnabled ? registeredOnly : false,
        couponStartDate: voucherEnabled ? couponStartDate : null,
        couponEndDate: voucherEnabled ? couponEndDate : null,
        visibility: selectedRadio === 1 ? "Published" : "Scheduled",
        scheduledDate: selectedRadio === 2 ? scheduledStartDate : null,
        scheduledTime: selectedRadio === 2 ? scheduledTime : null,
        returnPolicy: returnPolicyEnabled ? "yes" : "no",
        size6mmPrice: steelPrices.size6MMPrice || null,
        size8mmPrice: steelPrices.size8MMPrice || null,
        size10mmPrice: steelPrices.size10MMPrice || null,
        size12mmPrice: steelPrices.size12MMPrice || null,
        size16mmPrice: steelPrices.size16MMPrice || null,
        size20mmPrice: steelPrices.size20MMPrice || null,
        size25mmPrice: steelPrices.size25MMPrice || null,
        size32mmPrice: steelPrices.size32MMPrice || null,
        size6mmDiscountedPrice: steelPrices.size6MMDiscountedPrice || null,
        size8mmDiscountedPrice: steelPrices.size8MMDiscountedPrice || null,
        size10mmDiscountedPrice: steelPrices.size10MMDiscountedPrice || null,
        size12mmDiscountedPrice: steelPrices.size12MMDiscountedPrice || null,
        size16mmDiscountedPrice: steelPrices.size16MMDiscountedPrice || null,
        size20mmDiscountedPrice: steelPrices.size20MMDiscountedPrice || null,
        size25mmDiscountedPrice: steelPrices.size25MMDiscountedPrice || null,
        size32mmDiscountedPrice: steelPrices.size32MMDiscountedPrice || null,
      };

      const formData = new FormData();

      formData.append("productDto", JSON.stringify(productDto));

      if (mainImg) {
        formData.append("files", mainImg.originFileObj);
      }

      fileList.forEach((file) => {
        formData.append("files", file.originFileObj);
      });
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        "Content-Type": "multipart/form-data",
      };
      const response = await Instance.post(url, formData, { headers });

      if (response.status === 200 || response.status === 201) {
        console.log("Steel Prices:", steelPrices);
        console.log("Product added successfully:", response.data);
        message.success("Product added successfully");
        setProductName("");
        setSelectedCategory("");
        setSelectedBrand("");
        setSellingPrice("");
        setCost("");
        setQuantityInStock("");
        setShortDescription("");
        setLongDescription("");
        setOverview("");
        setApplications("");
        setFeatures("");
        setSpecifications("");
        setBenefits("");
        setUsageInstructions("");
        setCouponCode("");
        setCouponValue("");
        setCouponType("");
        setSelectedDate("");
        setScheduledStartDate("");
        setScheduledTime(null);
        setPreviewImage(null);
        setMainImg(null);
        setFileList([]);
        setproductSpecificationEnabled(false);
        setReturnPolicyEnabled(false);
        setErrors([]);
        setIsFormResetting(false);
      } else {
        console.error("Failed to add product:", response.data);
      }
    } catch (error) {
      message.error(error.response?.data?.message || "An error occurred");
      console.error("Failed to add product:", error);
    } finally {
      setLoading(false);
      setFormSubmitted(false);
    }
  };

  useEffect(() => {
    if (isFormResetting) return;
    const newErrors = { ...errors };

    if (formSubmitted) {
      if (!productName) {
        newErrors.productName = "Product Name is required";
      } else {
        delete newErrors.productName;
      }
    }
    if (formSubmitted) {
      if (!selectedCategory) {
        newErrors.selectedCategory = "Category is required";
      } else {
        delete newErrors.selectedCategory;
      }
    }
    if (formSubmitted) {
      if (!selectedBrand) {
        newErrors.selectedBrand = "Brand is required";
      } else {
        delete newErrors.selectedBrand;
      }
    }
    if (sellingPrice) {
      if (!sellingPrice) {
        newErrors.sellingPrice = "Selling price is required";
      } else {
        delete newErrors.sellingPrice;
      }
    }
    if (formSubmitted) {
      if (!cost) {
        newErrors.cost = "Cost is required";
      } else {
        delete newErrors.cost;
      }
    }
    if (formSubmitted) {
      if (!quantityInStock) {
        newErrors.quantityInStock = "Quantity is required";
      } else {
        delete newErrors.quantityInStock;
      }
    }
    if (formSubmitted) {
      if (!productSpecificationEnabled) {
        newErrors.productSpecificationEnabled =
          "Product Specifications must be enabled";
      } else {
        delete newErrors.productSpecificationEnabled;
      }
    }
    if (formSubmitted) {
      if (!mainImg) {
        newErrors.mainImg = "At least one must be uploaded";
      } else {
        delete newErrors.mainImg;
      }
    }
    if (formSubmitted) {
      if (selectedRadio === 2 && !scheduledStartDate) {
        newErrors.scheduledStartDate = "Scheduled Date is required";
      } else {
        delete newErrors.scheduledStartDate;
      }
    }
    if (formSubmitted) {
      if (selectedRadio === 2 && !scheduledTime) {
        newErrors.scheduledTime = "Scheduled Time is required";
      } else {
        delete newErrors.scheduledTime;
      }
    }
    if (formSubmitted) {
      if (!couponCode) {
        newErrors.couponCode = "Coupon Code is required";
      } else {
        delete newErrors.couponCode;
      }
    }
    if (formSubmitted) {
      if (!couponType) {
        newErrors.couponType = "Coupon Type is required";
      } else {
        delete newErrors.couponType;
      }
    }
    if (formSubmitted) {
      if (!couponValue) {
        newErrors.couponValue = "Coupon value is required";
      } else {
        delete newErrors.couponValue;
      }
    }
    if (formSubmitted) {
      if (!status) {
        newErrors.status = "Status is required";
      } else {
        delete newErrors.status;
      }
    }
    if (formSubmitted) {
      if (!couponStartDate) {
        newErrors.couponStartDate = "Coupon Start date is required";
      } else {
        delete newErrors.couponStartDate;
      }
    }
    if (formSubmitted) {
      if (!couponEndDate) {
        newErrors.couponEndDate = "Coupon End date is required";
      } else {
        delete newErrors.couponEndDate;
      }
    }
    if (
      shortDescription &&
      (shortDescription.length < 10 || shortDescription.length > 40)
    ) {
      newErrors.shortDescription =
        "Short Description must be between 10 and 40 characters";
    } else {
      delete newErrors.shortDescription;
    }

    if (
      longDescription &&
      (longDescription.length < 100 || longDescription.length > 1000)
    ) {
      newErrors.longDescription =
        "Long Description must be between 100 and 1000 characters";
    } else {
      delete newErrors.longDescription;
    }

    if (!overview && (overview.length < 250 || overview.length > 2000)) {
      newErrors.overview = "Overview must be between 250 and 2000 characters";
    } else {
      delete newErrors.overview;
    }

    // if (
    //   specifications &&
    //   (specifications.length < 250 || specifications.length > 2000)
    // ) {
    //   newErrors.specifications =
    //     "Specifications must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.specifications;
    // }

    // if (
    //   applications &&
    //   (applications.length < 250 || applications.length > 2000)
    // ) {
    //   newErrors.applications =
    //     "Applications must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.applications;
    // }

    // if (benefits && (benefits.length < 250 || benefits.length > 2000)) {
    //   newErrors.benefits = "Benefits must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.benefits;
    // }

    // if (
    //   usageInstructions &&
    //   (usageInstructions.length < 250 || usageInstructions.length > 2000)
    // ) {
    //   newErrors.usageInstructions =
    //     "Usage instructions must be between 250 and 2000 characters";
    // } else {
    //   delete newErrors.usageInstructions;
    // }

    if (!features && (features.length < 250 || features.length > 2000)) {
      newErrors.features = "Features must be between 250 and 2000 characters";
    } else {
      delete newErrors.features;
    }

    setErrors(newErrors);
  }, [
    productName,
    selectedCategory,
    selectedBrand,
    sellingPrice,
    cost,
    quantityInStock,
    productSpecificationEnabled,
    mainImg,
    scheduledStartDate,
    scheduledTime,
    couponCode,
    couponType,
    couponValue,
    status,
    couponStartDate,
    couponEndDate,
    shortDescription,
    longDescription,
    overview,
    specifications,
    applications,
    benefits,
    usageInstructions,
    features,
    formSubmitted,
    isFormResetting
  ]);

  const handleOverviewChange = (value) => {
    const trimmedValue = value.replace(/^<p>\s+/, "<p>"); // Remove leading spaces within the first <p> tag
    setOverview(trimmedValue);
  };
  return (
    <div className="container-fluid container">
      <div className="main-title-all">
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginBottom: "30px",
          }}
        >
          <span>Add Products</span>
        </div>
      </div>
      <div>
        <div className="col-lg-12 left_column">
          <div className="col-lg-12 mt-4 " style={{ textAlign: "left" }}>
            <label for="inputEmail4" className="form-label">
              Product Name
            </label>
            <input
              type="text"
              className="form-control form_control"
              placeholder="Enter Product Name"
              id="inputEmail4"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
            {errors.productName && (
              <span className="error-message">{errors.productName}</span>
            )}
          </div>
          <div className="row mt-2">
            <div
              className="col-lg-6 mt-3 search-table-container"
              style={{ textAlign: "left" }}
            >
              <label>Select Product Category</label>
              <Select
                options={Categoryoptions}
                className="mt-2"
                placeholder="Select Category"
                styles={{ ...customStyles, menuPortal: document.body }}
                onChange={handleCategoryChange}
                required
              />

              {selectedCategory?.value?.toLowerCase() === "steel" && (
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="pt-2">Size</h6>
                    <div className="card card-new-steel mb-2">
                      <div className="header-new-steel d-flex pb-2">
                        <p>Section</p>
                        <p>Cost&nbsp;Price</p>
                        <p>Selling&nbsp;Price</p>
                      </div>
                      {sections.map((section, index) => (
                        <div
                          key={section.id}
                          className="row row-new-steel d-flex"
                        >
                          <p>{section.section}</p>
                          <input
                            type="number"
                            min={0}
                            placeholder="₹0"
                            value={
                              steelPrices[`size${section.section}Price`] ||
                              section.price
                            }
                            onChange={(e) =>
                              handlePriceChange(
                                section.section,
                                `size${section.section}Price`,
                                e.target.value
                              )
                            }
                            style={{
                              border: "1px solid",
                              borderRadius: "10px",
                              padding: "2px 5px",
                              width: "100px",
                            }}
                          />
                          <input
                            type="number"
                            min={0}
                            placeholder="₹0"
                            value={
                              steelPrices[
                              `size${section.section}DiscountedPrice`
                              ] || section.costprice
                            }
                            onChange={(e) =>
                              handlePriceChange(
                                section.section,
                                `size${section.section}DiscountedPrice`,
                                e.target.value
                              )
                            }
                            style={{
                              border: "1px solid",
                              borderRadius: "10px",
                              padding: "2px 5px",
                              width: "100px",
                            }}
                          />
                          {errors[section.section] && (
                            <span className="error-message">
                              {errors[section.section]}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {errors.steelSize && (
                <span
                  className="error-message"
                  style={{ color: "red", marginLeft: "10px" }}
                >
                  {errors.steelSize}
                </span>
              )}

              {errors.selectedCategory && (
                <span className="error-message">{errors.selectedCategory}</span>
              )}
            </div>

            <div
              className="col-lg-6 mt-3 search-table-container"
              style={{ textAlign: "left" }}
            >
              <label>Select Brand</label>
              <Select
                options={selectedCategory ? brandOptions : []}
                className="mt-2"
                placeholder="select brand"
                styles={{ ...customStyles, menuPortal: document.body }}
                isDisabled={!selectedCategory}
                onChange={(selectedOption) =>
                  setSelectedBrand(selectedOption.value)
                }
                required
              />
              {errors.selectedBrand && (
                <span className="error-message">{errors.selectedBrand}</span>
              )}
              {selectedCategory?.value?.toLowerCase() === "steel" && (
                <div className="mt-4 ">
                  <div className="mt-4" style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Cost
                    </label>
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="&#8377;0"
                      id="inputEmail4"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                      // style={{width:"300px"}}
                      required
                    />
                    {errors.cost && (
                      <span className="error-message">{errors.cost}</span>
                    )}
                  </div>
                  <div className=" mt-4 " style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Selling Price
                    </label>
                    <input
                      type="number"
                      min={0}
                      className="form-control form_control"
                      placeholder="&#8377;0"
                      id="inputEmail4"
                      value={sellingPrice}
                      // style={{width:"300px"}}
                      onChange={(e) => setSellingPrice(e.target.value)}
                      required
                    />
                    {errors.sellingPrice && (
                      <span className="error-message">
                        {errors.sellingPrice}
                      </span>
                    )}
                  </div>
                  <div className=" mt-4 " style={{ textAlign: "left" }}>
                    <label for="inputEmail4" className="form-label">
                      Quantity in Stock
                    </label>
                    <input
                      type="number"
                      className="form-control form_control"
                      placeholder="Enter quantity"
                      id="inputEmail4"
                      value={quantityInStock}
                      onChange={(e) => setQuantityInStock(e.target.value)}
                      required
                    />
                    {errors.quantityInStock && (
                      <span className="error-message">
                        {errors.quantityInStock}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {selectedCategory?.value?.toLowerCase() !== "steel" && (
            <div className="row ">
              <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                <label for="inputEmail4" className="form-label">
                  Cost
                </label>
                <input
                  type="number"
                  className="form-control form_control"
                  placeholder="&#8377;0"
                  id="inputEmail4"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                  required
                />
                {errors.cost && (
                  <span className="error-message">{errors.cost}</span>
                )}
              </div>
              <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                <label for="inputEmail4" className="form-label">
                  Selling Price
                </label>
                <input
                  type="number"
                  className="form-control form_control"
                  placeholder="&#8377;0"
                  id="inputEmail4"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  required
                />
                {errors.sellingPrice && (
                  <span className="error-message">{errors.sellingPrice}</span>
                )}
              </div>
              <div className="col-lg-4 mt-4 " style={{ textAlign: "left" }}>
                <label for="inputEmail4" className="form-label">
                  Quantity in Stock
                </label>
                <input
                  type="number"
                  className="form-control form_control"
                  placeholder="Enter quantity"
                  id="inputEmail4"
                  value={quantityInStock}
                  onChange={(e) => setQuantityInStock(e.target.value)}
                  required
                />
                {errors.quantityInStock && (
                  <span className="error-message">
                    {errors.quantityInStock}
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="add_voucher mt-4">
            <div>
              <p className="gray_text_p">Add voucher</p>
            </div>
            <div>
              <span className="m-2">Voucher</span>
              <Switch
                className="switch-input"
                checked={voucherEnabled}
                onChange={handleVoucherToggle}
              />
            </div>
          </div>
          {voucherEnabled && (
            <>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <p className="add_couponpage_hedings d-flex justify-content-start">
                    Coupon Information
                  </p>
                  <Input
                    placeholder="Coupon Code"
                    value={couponCode}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                      setCouponCode(value);
                    }}
                    className="form-control custom-placeholder"
                    style={{ width: "75%" }}
                  />
                  {errors.couponCode && (
                    <div className="error error-add-product">
                      {errors.couponCode}
                    </div>
                  )}
                </div>

                <div className="col-lg-6 mb-3">
                  <p className="add_couponpage_hedings d-flex justify-content-start">
                    Status
                  </p>
                  <Radio.Group
                    className="add_coupon_radio"
                    name="status"
                    style={{ display: "flex", gap: "10px" }}
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                  >
                    <Radio value={ENABLED}>Enabled</Radio>
                    <Radio value={DISABLED}>Disabled</Radio>
                  </Radio.Group>
                  {errors.status && (
                    <div className="error error-add-product">
                      {errors.status}
                    </div>
                  )}
                </div>
                <div className="d-flex voucher-coupon-type">
                  <div className="col-lg-6 mb-3">
                    <p className="add_couponpage_hedings d-flex justify-content-start">
                      Coupon Type
                    </p>
                    <Radio.Group
                      className="add_coupon_radio"
                      name="couponType"
                      style={{ display: "flex", gap: "2px" }}
                      value={couponType}
                      onChange={(e) => setCouponType(e.target.value)}
                    >
                      <Radio value={PERCENTAGE}>Percentage</Radio>
                      <Radio value={DISCOUNT_AMOUNT}>Discount Amount</Radio>
                      <Radio value={FREE_SHIPPING}>Free Shipping</Radio>
                    </Radio.Group>
                    {errors.couponType && (
                      <div className="error error-add-product">
                        {errors.couponType}
                      </div>
                    )}
                    {(couponType === PERCENTAGE ||
                      couponType === DISCOUNT_AMOUNT) && (
                        <div
                          className="col-lg-6 mb-4"
                          style={{ marginTop: "10px" }}
                        >
                          <Input
                            placeholder="Enter Value"
                            className="form-control"
                            min={0}
                            style={{ width: "60%" }}
                            value={couponValue}
                            onChange={(e) => handleValueChange(e.target.value)} 
                          />
                          {errors.couponValue && (
                            <div className="error error-add-product">
                              {errors.couponValue}
                            </div>
                          )}
                        </div>
                      )}
                  </div>

                  <div className="col-lg-6 mb-3 mt-2">
                    <Checkbox
                      className="add_coupon_checkbox d-flex justify-content-start"
                      checked={registeredOnly}
                      onChange={(e) => setregisteredOnly(e.target.checked)}
                    >
                      Only for Registered Customers
                    </Checkbox>
                    {errors.registeredOnly && (
                      <div className="error error-add-product">
                        {errors.registeredOnly}
                      </div>
                    )}
                  </div>
                </div>
                <p className="add_couponpage_hedings d-flex justify-content-start">
                  Schedule
                </p>
                <div className="col-lg-6 mb-3">
                  <DatePicker
                    value={
                      couponStartDate
                        ? moment(couponStartDate, "YYYY-MM-DD HH:mm:ss")
                        : null
                    }
                    onChange={handleStartDateChange}
                    className="product_input_field"
                    style={{ width: "100%" }}
                    placeholder="Select coupon start date"
                    disabled={!voucherEnabled}
                  />
                  {errors.couponStartDate && (
                    <div className="error">{errors.couponStartDate}</div>
                  )}
                </div>
                <div className="col-lg-6 mb-3">
                  <DatePicker
                    value={
                      couponEndDate
                        ? moment(couponEndDate, "YYYY-MM-DD HH:mm:ss")
                        : null
                    }
                    onChange={handleEndDateChange}
                    style={{ width: "100%" }}
                    className="product_input_field"
                    placeholder="Select coupon end date"
                    disabled={!voucherEnabled}
                  />
                  {errors.couponEndDate && (
                    <div className="error">{errors.couponEndDate}</div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {/* right column  */}

        <div className="col-lg-12">
          <div className="row ">
            <div className="col-lg-6">
              <p style={{ textAlign: "left" }} className="mb-1 mt-3">
                Short description
              </p>
              <Input.TextArea
                rows={6}
                placeholder="Short Description"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                required
              />
              {errors.shortDescription && (
                <span className="error-message">{errors.shortDescription}</span>
              )}
            </div>
            <div className="col-lg-6 mt-2">
              <div className="editor-container">
                <p className="product_description_heading">
                  Product Long Description
                </p>
                <div className="react-quill-wrapper">
                  <div className="react-quill-toolbar"></div>
                  <div className="react-quill-container">
                    <ReactQuill
                      value={longDescription}
                      onChange={setLongDescription}
                      placeholder="Your text goes here"
                      modules={modules}
                      formats={formats}
                      className="edit-reactquill"
                    />
                  </div>
                </div>
                {errors.longDescription && (
                  <span className="error-message">
                    {errors.longDescription}
                  </span>
                )}
              </div>
              <p className="long_description_p">
                Add a long description for your product
              </p>
            </div>
          </div>
          <div className="add_voucher mt-4 col-lg-12">
            <div>
              <p className="gray_text_p">Product Specifications</p>
            </div>
            <div>
              {/* <span className="m-2">Specifications</span> */}
              <Switch
                className="switch-input"
                checked={productSpecificationEnabled}
                onChange={handleproductSpecificationToggle}
              />
            </div>
          </div>
          {errors.productSpecificationEnabled && (
            <span className="error error-add-product mt-0">
              {errors.productSpecificationEnabled}
            </span>
          )}
          {productSpecificationEnabled && (
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <p className="product_description_heading">Overview</p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={overview}
                        onChange={handleOverviewChange}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.overview && (
                    <span className="error-message">{errors.overview}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <p className="product_description_heading">Features</p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={features}
                        onChange={setFeatures}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.features && (
                    <span className="error-message">{errors.features}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <p className="product_description_heading">Specifications</p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={specifications}
                        onChange={handleSpecificationsChange}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.specifications && (
                    <span className="error-message">
                      {errors.specifications}
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <p className="product_description_heading">Applications</p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={applications}
                        onChange={handleApplicationsChange}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.applications && (
                    <span className="error-message">{errors.applications}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <p className="product_description_heading">Benefits</p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={benefits}
                        onChange={handleBenefitsChange}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.benefits && (
                    <span className="error-message">{errors.benefits}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <p className="product_description_heading">
                    Usage instructions
                  </p>
                  <div className="react-quill-wrapper">
                    <div className="react-quill-toolbar"></div>
                    <div className="react-quill-container">
                      <ReactQuill
                        value={usageInstructions}
                        onChange={handleUsageInstructionsChange}
                        placeholder="Your text goes here"
                        modules={modules}
                        formats={formats}
                        className="edit-reactquill"
                      />
                    </div>
                  </div>
                  {errors.usageInstructions && (
                    <span className="error-message">
                      {errors.usageInstructions}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-lg-6 visibility order-lg-1 order-2">
              <div>
                <p className="visibility_header">Visibility</p>
                <Radio.Group
                  className="add_coupon_radio"
                  onChange={handleRadioChange}
                  value={selectedRadio}
                  required
                >
                  <Radio value={1}>Published</Radio>
                  <Radio value={2}>Scheduled</Radio>
                </Radio.Group>
              </div>
              {selectedRadio === 2 && (
                <>
                  <p className="date_added__heding mt-2">Date added</p>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <DatePicker
                        value={
                          scheduledStartDate
                            ? moment(scheduledStartDate, "YYYY-MM-DD HH:mm:ss")
                            : null
                        }
                        className="custom-date-picker"
                        onChange={handlescheduledStartDateChange}
                        placeholder="Start date"
                      />
                      {errors.scheduledStartDate && (
                        <span className="error-message">
                          {errors.scheduledStartDate}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <TimePicker
                        value={
                          scheduledTime
                            ? moment(scheduledTime, "HH:mm:ss")
                            : null
                        }
                        onChange={onChangeTime}
                        className="custom-date-picker"
                      />
                      {errors.scheduledTime && (
                        <span className="error-message">
                          {errors.scheduledTime}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6 add_voucher mt-2 order-lg-2 order-1">
              <div>
                <p className="gray_text_p">Return Policy</p>
              </div>
              <div>
                <span className="m-2"></span>
                <Switch
                  className="switch-input"
                  checked={returnPolicyEnabled}
                  onChange={handleReturnPolicy}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <h6 className="mt-3">Add images</h6>
          <div className="add_image_div mt-4 ">
            <div className="add_image_icons">
              <label htmlFor="uploadInput">
                <FiUploadCloud className="add_image_upload_icon" />
              </label>
              <input
                type="file"
                id="uploadInput"
                style={{ display: "none" }}
                onChange={handleMainImageChange}
              />
              <AiOutlineDelete
                className="add_image_delete_icon"
                onClick={handleDeleteImage}
              />
            </div>
            <div>
              <img
                src={
                  mainImg
                    ? URL.createObjectURL(mainImg.originFileObj)
                    : product_4
                }
                className="image_1"
                alt="Preview"
              />
            </div>
            {errors.mainImg && (
              <span className="error-message">{errors.mainImg}</span>
            )}
          </div>
        </div>

        <div className="col-lg-6 ">
          <h6 className="mt-3">Additional Images</h6>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 3 ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </div>
      </div>

      <div className="last_row_buttons_div mt-4">
        <button className="add_product_discard_button">Discard</button>
        <button
          className="add_product_save_publish_button"
          onClick={handleSubmit}
        >
          {loading ? (
            <Spin size="small" />
          ) : (
            "Save & Publish"
          )}
        </button>
      </div>

    </div>
  );
};

export default AddProduct;
