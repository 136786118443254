import { configureStore } from '@reduxjs/toolkit';
import FAQReducer  from './states/reducers/FAQslice';
import AuthReducer from './states/reducers/AuthSlice';
import BrandReducer from './states/reducers/BrandSlice';
import UserReducer from './states/reducers/UserSlice'
const store = configureStore({
  reducer: {
   faq: FAQReducer,
   auth:AuthReducer,
   brand:BrandReducer,
   user:UserReducer,
  },
});

export default store;
