import React, { useState, useRef, useEffect } from 'react';
import './Settings.css';
import { CgProfile } from 'react-icons/cg';
import { FaKey } from 'react-icons/fa';
import { MdStorefront } from 'react-icons/md';
import { DatePicker, message } from 'antd';
import Password from './Password';
import StoreInformation from './StoreInformation';
import ImgUrl from "../../Assets/images/singleuser.png";
import { PiNotePencilBold } from "react-icons/pi";
import Instance from '../../AxiosConfig';
import moment from 'moment';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../states/reducers/UserSlice';

const PersonalInfo = () => {
    const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();
    const [activeSection, setActiveSection] = useState('profile');
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialSection = query.get('section') || 'profile';
    const [errors, setErrors] = useState();
    const [isClearedOnce, setIsClearedOnce] = useState(false);
    const [formData, setFormData] = useState({
        profilePicture: ImgUrl,
        fullName: '',
        email: '',
        dateOfBirth: '',
        mobile: '',
        streetAddress: '',
    });
    const [originalData, setOriginalData] = useState({});

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const response = await Instance.get("/api/v1/user/", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setUserData(data);
                const formattedData = {
                    profilePicture: data.imageData ? `data:image/jpeg;base64,${data.imageData}` : ImgUrl,
                    fullName: data?.fullName || '',
                    email: data.email || '',
                    dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : null,
                    mobile: data.mobile !== 0 ? String(data.mobile) : '',
                    streetAddress: data.streetAddress || ''
                };

                setFormData(formattedData);
                setOriginalData(formattedData);
                console.log("User data fetched successfully:", response.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            message.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        const section = query.get('section');
        if (section) {
            setActiveSection(section);
        }
    }, [location.search]);

    const hasChanges = () => {
        return JSON.stringify(formData) !== JSON.stringify(originalData);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const fileInputRef = useRef(null);

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await Instance.put("/api/v1/admin/upload-image", formData, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "multipart/form-data",
                }
            });
            if (response.status === 200) {
                message.success("Image uploaded successfully");
                setFormData((prevData) => ({
                    ...prevData,
                    profilePicture: URL.createObjectURL(file),
                }));
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            message.error(error?.response?.data?.message || "An error occurred while uploading the image");
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevData) => ({
                    ...prevData,
                    profilePicture: reader.result,
                }));
            };
            reader.readAsDataURL(file);
            uploadImage(file);
        }
    };

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const getActiveClass = (section) => (activeSection === section ? 'active' : '');

    const enableEditMode = () => {
        setEditMode(true);
    };

    const updateUser = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            message.error("Please fix the errors in the form.");
            return;
        }
        if (!hasChanges()) {
            message.info("No changes detected");
            return;
        }
        try {
            setLoading(true);
            const updatedData = {
                ...formData,
                dateOfBirth: formData.dateOfBirth ? formData.dateOfBirth.format('YYYY-MM-DD') : null
            };

            console.log("Sending updated data:", updatedData);
            const response = await Instance.put("/api/v1/user/update", updatedData, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                }
            });

            if (response.status === 200) {
                message.success("User data updated successfully");
                console.log("After update:", response);
                dispatch(setUserData(response.data));
                console.log("Updated user data in state:", userData);
            }
        } catch (error) {
            console.error("Error updating user data:", error);
            message.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
            setEditMode(false);
        }
    };

    const validateFields = () => {
        let isValid = true;
        let newErrors = {};

        if (!formData || !formData?.fullName) {
            newErrors.fullName = "Full name is required.";
            isValid = false;
        } else if (!/^[a-zA-Z\s]{3,16}$/.test(formData?.fullName)) {
            newErrors.fullName = "User name must be between 3-16 letters";
            isValid = false;
        }

        if (!formData || !formData.email) {
            newErrors.email = "Email is required.";
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = "Enter a valid email address.";
            isValid = false;
        }

        if (!formData || !formData?.mobile) {
            newErrors.mobile = "Mobile number is required.";
            isValid = false;
        } else if (!/^[5-9]/.test(formData.mobile)) {
            newErrors.mobile = "Mobile number should start from 5-9";
            isValid = false;
        }

        if (!formData || !formData.streetAddress) {
            newErrors.streetAddress = "Address is required.";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };


    return (
        <div className="container-fluid d-flex settings-personal-info-div">
            <div className='row' style={{ width: "100%" }}>
                <div className='col-lg-4'>
                    <div className="setting-sidebar card">
                        <div
                            onClick={() => setActiveSection('profile')}
                            className={`settings-items ${getActiveClass('profile')}`}
                        >
                            <CgProfile style={{ width: '20px', height: '20px' }} /> &nbsp; Profile Information
                        </div>
                        <div
                            onClick={() => setActiveSection('password')}
                            className={`settings-items ${getActiveClass('password')}`}
                        >
                            <FaKey style={{ width: '20px', height: '20px' }} /> &nbsp; Password
                        </div>
                        <div
                            onClick={() => setActiveSection('store')}
                            className={`settings-items ${getActiveClass('store')}`}
                        >
                            <MdStorefront style={{ width: '20px', height: '20px' }} /> &nbsp; Store Information
                        </div>
                    </div>
                </div>
                <div className='col-lg-8'>
                    <div className="content-profile">
                        {activeSection === 'profile' && (
                            <>
                                <h4 className="text-align-left d-flex pb-2">Personal Information</h4>
                                <div className="profile-icon-section pb-4">
                                    <img src={formData.profilePicture} alt="Profile" className="profile-image" />
                                    <button type="button" className="edit-icon-button" onClick={handleEditClick}>
                                        <PiNotePencilBold style={{ width: "24px", height: "24px" }} />
                                    </button>

                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                </div>
                                <form onSubmit={updateUser}>
                                    <div className="row pb-4 settings-personalinfo-row">
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Full Name"
                                                aria-label="User name"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData?.fullName}
                                                onChange={(e) => {
                                                    let value = e.target.value;

                                                    // Limit the value to 16 characters
                                                    if (value.length > 16) {
                                                        value = value.slice(0, 16);
                                                    }

                                                    if (/^[a-zA-Z\s]*$/.test(value)) {
                                                        setFormData({ ...formData, fullName: value });
                                                        setErrors((prev) => ({ ...prev, fullName: '' })); // Clear error
                                                    } else {
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            fullName: 'User name must contain only letters and spaces.',
                                                        }));
                                                    }
                                                }}
                                                readOnly={!editMode}
                                            />

                                            {errors?.fullName && <div className="error-message text-danger">{errors.fullName}</div>}
                                        </div>

                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Email</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                aria-label="email"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                readOnly
                                                onClick={() => message.error('Cannot be edited')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pb-4 settings-personalinfo-row">
                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Date of Birth</label>
                                            <DatePicker
                                                className="form-control profilr-date    "
                                                onChange={(date) => {
                                                    setFormData({ ...formData, dateOfBirth: date });
                                                    setIsClearedOnce(true);
                                                }}
                                                value={formData.dateOfBirth}
                                                placeholder="Enter Date of Birth"
                                                aria-label="Date of birth"
                                                disabled={!editMode}
                                                style={{ borderRadius: "7px" }}
                                                disabledDate={(current) => current && current > moment().endOf('day')}
                                                onKeyDown={(e) => {
                                                    const allowedKeys = /[0-9/-]/;
                                                    if (
                                                        !allowedKeys.test(e.key) &&
                                                        !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    if (!isClearedOnce && formData.dateOfBirth && e.target.value === formData.dateOfBirth.format('YYYY-MM-DD')) {
                                                        setFormData({ ...formData, dateOfBirth: null });
                                                        setIsClearedOnce(true);
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className="col">
                                            <label className="text-align-left d-flex pb-2">Mobile Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Phone Number"
                                                aria-label="mobile number"
                                                style={{ padding: "10px", borderRadius: "7px" }}
                                                value={formData?.mobile}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d{0,10}$/.test(value)) {
                                                        setFormData({ ...formData, mobile: value });
                                                    }
                                                }}
                                                readOnly={!editMode}
                                            />
                                            {errors?.mobile && <div className="error-message text-danger">{errors?.mobile}</div>}
                                        </div>

                                    </div>
                                    <div className="col">
                                        <label className="text-align-left d-flex pb-2">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Address"
                                            aria-label="streetAddress"
                                            style={{ padding: "10px", borderRadius: "7px" }}
                                            value={formData.streetAddress}
                                            onChange={(e) => setFormData({ ...formData, streetAddress: e.target.value })}
                                            readOnly={!editMode}
                                        />
                                        {errors?.streetAddress && <div className="error-message text-danger">{errors.streetAddress}</div>}
                                    </div>
                                    <div className="d-flex justify-content-end pt-4 gap-2 settings-personalinfo-buttons">

                                        <button
                                            type="button"
                                            style={{ backgroundColor: "#f6f6f6", border: "none", borderRadius: "15px", color: "#1b3a57", padding: "10px 30px" }}
                                            onClick={enableEditMode}

                                        >
                                            <MdOutlineModeEditOutline /> Edit
                                        </button>

                                        <button
                                            type="submit"
                                            style={{ backgroundColor: "#1b3a57", border: "none", borderRadius: "15px", color: "#fff", padding: "10px 15px" }}
                                        >
                                            Save Changes
                                        </button>

                                    </div>
                                </form>
                            </>
                        )}
                        {activeSection === 'password' && <Password />}
                        {activeSection === 'store' && <StoreInformation />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
