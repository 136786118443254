import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, DatePicker, Select, Spin } from "antd";
import './ManageAdmins.css';
import Instance from "../../AxiosConfig";
import { DeleteOutlined } from "@ant-design/icons";

const NewUser = ({ isModalVisible, setIsModalVisible }) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState();

    const handleSubmit = async () => {
        setLoading(false);
        try {
            const values = await form.validateFields();
            const userData = {
                fullName: values.name,
                email: values.email,
                password: values.password,
                mobile: values.mobile,
                dateOfBirth: values.dob.format("YYYY-MM-DD"),
                roleName: values.role,
                streetAddress: values.address,
            };

            const response = await Instance.post('/api/v1/admin/create', userData, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                setIsModalVisible(false);
                message.success('User created successfully!');
                form.resetFields();
                setLoading(false)
            }
        } catch (error) {
            if (error.response) {
                message.error(`Error: ${error.response.data.message}`);
            } else {
                message.error('Please complete the form correctly.');
                console.log('Validate Failed:', error);
            }
        } finally {
            setLoading(false);
        }
    };


    const handleDeleteRole = async (roleId) => {
        console.log("RoleId to delete", roleId);
        try {
            const url = `/api/v1/roleAccess/${roleId}`;
            const response = await Instance.delete(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log("DELETE RESPONSE", response.data)
                const successMessage = response.data?.message || "Role deleted successfully.";
                message.success(successMessage);
                fetchRoles();

            }
        } catch (error) {
            console.error({ error });
            message.error(error.response.data.message);
        }
    };
    const fetchRoles = async () => {
        try {
            const response = await Instance.get("api/v1/roleAccess/roles", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                },
            });

            const uniqueRoles = Array.from(
                new Map(
                    response.data
                        .filter(role => role.roleName !== 'ROLE_NORMAL')
                        .map(role => [
                            role.roleName,
                            {
                                value: role.roleName,
                                label: role.roleName.replace(/_/g, ' '),
                                roleId: role.roleId
                            }
                        ])
                ).values()
            );

            setRoles(response.data);
            setRoleOptions(uniqueRoles);

            if (uniqueRoles.length > 0) {
                setSelectedRole(uniqueRoles[0].value);
            }

            console.log("ROLES:", uniqueRoles);
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);


    return (
        <Modal
            title="New User"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <div className="d-flex justify-content-end gap-2" key="footer">
                    <button
                        style={{
                            backgroundColor: "#f6f6f6",
                            border: "none",
                            borderRadius: "15px",
                            padding: "10px 20px",
                        }}
                        onClick={() => setIsModalVisible(false)}
                    >
                        Discard
                    </button>
                    <button
                        style={{
                            backgroundColor: "#1b3a57",
                            border: "none",
                            borderRadius: "15px",
                            color: "#fff",
                            padding: "10px 20px",
                        }}
                        onClick={handleSubmit}
                    >
                        {loading ? (
                            <Spin size="small" />
                        ) : (
                            "Save"
                        )}

                    </button>
                </div>,
            ]}
        >
            <div className="add-brand-section pt-2">
                <Form
                    form={form}
                    layout="vertical"
                    name="new_user_form"
                >
                    <div className="row">
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Full Name</span>}
                                name="name"
                                rules={[
                                    { required: true, message: 'Please enter your full name!' },
                                    {
                                        pattern: new RegExp(/^[a-zA-Z\s]{3,16}$/),
                                        message: 'Full name must be between 3 to 16 letters!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Full Name"
                                    className="form-control custom-placeholder"
                                    maxLength={16}
                                    onKeyPress={(event) => {
                                        // Prevent typing numbers
                                        if (!/^[a-zA-Z\s]*$/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Email</span>}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please inter your email!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Password</span>}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password!'
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
                                        message: 'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character!',
                                    }
                                ]}
                            >
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Mobile</span>}
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your mobile number!',
                                    },
                                    {
                                        pattern: new RegExp(/^[0-9]{10}$/),
                                        message: 'The input is not a valid mobile number!',
                                    },
                                    {
                                        pattern: new RegExp(/^[5-9][0-9]{9}$/),
                                        message: 'Mobile number must start with 5, 6, 7, 8, or 9!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Mobile"
                                    className="form-control custom-placeholder"
                                    maxLength={10}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Date of Birth</span>}
                                name="dob"
                                rules={[{ required: true, message: 'Please enter your date of birth!' }]}
                            >
                                <DatePicker
                                    placeholder="Date of Birth"
                                    className="form-control custom-placeholder"
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) => {
                                        const today = new Date();
                                        const selectedDate = current ? current.toDate() : null;
                                        return selectedDate && selectedDate > today;
                                    }}
                                    onKeyDown={(e) => {
                                        const allowedKeys = /[0-9/-]/;
                                        if (
                                            !allowedKeys.test(e.key) &&
                                            !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Role Name</span>}
                                rules={[{ required: true, message: "Please select a role!" }]}
                                name="role"
                            >
                                <Select
                                    value={selectedRole}
                                    onChange={(value) => {
                                        setSelectedRole(value);
                                        console.log("Selected Role:", value);
                                    }}
                                    placeholder="Select a role"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    {roleOptions.map((role) => (
                                        <Select.Option key={role.value} value={role.value}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <span>{role.label}</span>
                                                <DeleteOutlined
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (role.roleId !== selectedRole) {
                                                            handleDeleteRole(role.roleId);
                                                        }
                                                    }}
                                                    style={{
                                                        color: role.value === selectedRole ? "transparent" : "red",
                                                        cursor: role.value === selectedRole ? "not-allowed" : "pointer",
                                                    }}
                                                // disabled={role.value === selectedRole} // Optional, purely for semantic clarity
                                                />
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>




                        <div className="col-lg-12 mb-1">
                            <Form.Item
                                label={<span className="manageadmins-new-user-label">Address</span>}
                                name="address"
                                rules={[{ required: true, message: 'Please enter your address!' }]}
                            >
                                <Input.TextArea
                                    placeholder="Address"
                                    className="form-control custom-placeholder"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default NewUser;
