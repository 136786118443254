import React, { useState } from "react";
import "./Auth.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import adminLogo from "../../Assets/images/white-bg-logo.png"

export const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) {
      return "Email is required";
    } else if (/^\d/.test(trimmedEmail)) {
      return "Email should not start with a number";
    } else if (/[A-Z]/.test(trimmedEmail)) {
      return "Email should not contain uppercase letters";
    } else if (/\s/.test(trimmedEmail)) {
      return "Email should not contain spaces";
    } else if (!/@/.test(trimmedEmail) || !/\./.test(trimmedEmail)) {
      return "Email must contain '@' and a domain (e.g., '.com')";
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(trimmedEmail)) {
      return "Email is invalid";
    } else {
      return "";
    }
  };

  const validatePassword = (password) => {
    const trimmedPassword = password.trim();
    if (!trimmedPassword) {
      return "Password is required";
    } else if (trimmedPassword.length < 8) {
      return "Password must be at least 8 characters";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(trimmedPassword)) {
      return "Password must contain a special character";
    } else if (!/\d/.test(trimmedPassword)) {
      return "Password must contain a number";
    } else if (/\s/.test(trimmedPassword)) {
      return "Password should not contain spaces";
    } else {
      return "";
    }
  };
  console.log("Form submitted");
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailError = validateEmail(newEmail);
    setErrors((prevErrors) => ({ ...prevErrors, email: emailError }));
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const passwordError = validatePassword(newPassword);
    setErrors((prevErrors) => ({ ...prevErrors, password: passwordError }));
  };

  const validateForm = () => {
    const emailError = validateEmail(email)
      ;
    const passwordError = validatePassword(password);

    setErrors({ email: emailError, password: passwordError });

    return !emailError && !passwordError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await Instance.post(`/api/v1/auth/login`, {
          email,
          password,
        });
        console.log(response.data);

        const { jwtToken, user } = response.data;

        if (user && user.roles && Array.isArray(user.roles)) {
          const hasNormalRole = user.roles.some((role) => role.roleName === "ROLE_NORMAL");

          if (!hasNormalRole) {
            sessionStorage.setItem("adminAuthToken", jwtToken);
            sessionStorage.setItem('userData', JSON.stringify(user.roles[0]?.roleName));
            toast.success("Login successfully!");
  
            setTimeout(() => {
              navigate("/dashboard");
            }, 2000);
          } else {
            toast.error("Access denied! Only administrators can log in.");
          }
        } else {
          toast.error("Roles information is missing. Please contact support.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);

        if (error.response) {
          console.log("Error response:", error.response);
          let errorMessage = "Login failed!";
          if (error.response.data && error.response.data.message) {
            const serverMessage = error.response.data.message;
            if (serverMessage.includes(":")) {
              errorMessage = serverMessage.split(":")[0];
            } else {
              errorMessage = serverMessage;
            }
          }
          toast.error(errorMessage);
        } else {
          toast.error("Login failed!");
        }
      }
    }
  };

  return (
    <div>
      <div className="login-page-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ marginTop: "100px" }}  
        />

        <img src={adminLogo} alt="Admin Logo" className="login-admin-logo"  />
        <div className="login-details">
          <h6>Welcome to Builderneed</h6>
          <h4>LOGIN</h4>
          <form className="login-form" onSubmit={handleSubmit}>
            <label htmlFor="email" className="mt-4">
              Enter your username or email address
            </label>
            <div className="email-container">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="robert.langster@gmail.com"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            {errors.email && <p className="error">{errors.email}</p>}

            <label htmlFor="password" className="mt-4">
              Password
            </label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {errors.password && <p className="error">{errors.password}</p>}

            <div className="form-options">
              <label>
                <input type="checkbox" name="remember" />
                Remember me
              </label>

              <button type="button" onClick={handleForgotPassword} className="forgot-password">
                Forgot Password
              </button>
            </div>

            <div className="d-flex button-container gap-4">
              <button type="submit" className="login-btn" id="login-button">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};