import React, { useState, useEffect } from "react";
import { Modal, Input, message, Spin } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import product_4 from "../../Assets/images/Gallery.png";
import Instance from "../../AxiosConfig";
import Select from "react-select";
import "./Brands.css";
import { useDispatch } from "react-redux";
import { editBrand } from "../../states/reducers/BrandSlice";

const EditBrands = ({ isModalVisible, setIsModalVisible, selectedProduct, fetchData, onClose }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const dispatch = useDispatch();
  const [mainImg, setMainImg] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({
    brandName: "",
    category: "",
    totalBag: "",
    remainingBag: "",
    availability: "",
    totalKg: "",
  });
  const [errors, setErrors] = useState({});
  const fetchImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "brandImage.jpg", { type: blob.type });
      setMainImg(file);
    } catch (error) {
      console.error("Error fetching image:", error);
      message.error("Failed to fetch image.");
    }
  };


  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setMainImg(file);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setErrors({
      ...errors,
      image: "",
    });
  };

  const handleDeleteImage = () => {
    setMainImg(null);
  };

  useEffect(() => {
    if (selectedProduct) {
      setFormData({
        brandName: selectedProduct.brandName,
        category: selectedProduct.categories,
        availability: selectedProduct.availability,
        totalKg: selectedProduct.totalKg,
      });


      function base64ToBlob(base64, contentType = 'image/jpeg') {
        const binaryString = atob(base64); // Decode Base64 string
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: contentType });
      }

      if (selectedProduct.imageData) {
        const base64Data = selectedProduct.imageData; // The Base64 string without prefix
        const blob = base64ToBlob(base64Data);
        const imageUrl = URL.createObjectURL(blob);

        // Use the imageUrl as needed
        fetchImage(imageUrl);
        console.log("zzz", imageUrl)
      }

      setSelectedCategory({
        value: selectedProduct.categories,
        label: selectedProduct.categories,
      });
    }
  }, [selectedProduct]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption.value,
    });
    setSelectedCategory(selectedOption);
    setErrors({
      ...errors,
      category: "",
    });
  };

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      availability: e.target.value,
    });
    setErrors({
      ...errors,
      availability: "",
    });
  };



  const getProductCategory = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Categories fetched:", response.data);
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductCategory();
  }, []);

  const Categoryoptions = categories.map((category) => ({
    value: category.categoryTitle,
    label: category.categoryTitle,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  const resetForm = () => {
    setFormData({
      brandName: "",
      category: "",
      totalBag: "",
      remainingBag: "",
      availability: "",
      totalKg: "",
    });
    setMainImg(null);
    setSelectedCategory(null);
  };

  const validateForm = () => {
    const { brandName, category, totalBag, remainingBag, availability, totalKg } = formData;
    const newErrors = {};
    if (!brandName) newErrors.brandName = "Brand name is required";
    if (!category) newErrors.category = "Category is required";
    if (!availability) newErrors.availability = "Availability is required";
    if (!mainImg) newErrors.image = "Image is required";
    if (!totalKg) newErrors.totalKg = "Enter Kg"

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!validateForm()) {
      return;
    }
    const { brandId } = selectedProduct;
    console.log(brandId)
    const brandDto = {
      brandName: formData.brandName,
      categories: formData.category,
      availability: formData.availability,
      totalKg: formData.totalKg
    };

    const form = new FormData();
    form.append("brandDto", JSON.stringify(brandDto));
    if (mainImg) {
      form.append("file", mainImg);
    }

    try {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        // "Content-Type": "multipart/form-data",
      };

      const url = `api/v1/brand/update/${brandId}`;
      console.log("ac", brandId)
      const response = await Instance.put(url, form, {
        headers,
      });

      if (response.status === 200 || response.status === 201) {
        let imageData = '';
        if (mainImg) {
          imageData = await convertToBase64(mainImg);
          imageData = imageData.split(',')[1];
        }

        message.success("Brand Updated successfully!");

        const payloadToDispatch = {
          ...brandDto,
          imageData,
          name: formData.brandName,
          brandId
        };

        console.log("Dispatch Payload:", payloadToDispatch);
        dispatch(editBrand(payloadToDispatch))
        fetchData();
        resetForm();
        onClose();
        setIsModalVisible(false);
      } else {
        message.error("Failed to Update brand.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating brand:", error);
      message.error("Failed to update brand.");
      setLoading(false);
    }


  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  return (
    <Modal
      title="Edit Brand"
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={onClose}
      // onCancel={() => setIsModalVisible(false)}
      footer={[
        <div className="d-flex justify-content-end gap-2">
          <button
            style={{
              backgroundColor: "#f6f6f6",
              border: "none",
              borderRadius: "15px",
              padding: "10px 20px",
            }}
            onClick={() => setIsModalVisible(false)}
          >
            Discard
          </button>
          <button
            style={{
              backgroundColor: "#1b3a57",
              border: "none",
              borderRadius: "15px",
              color: "#fff",
              padding: "10px 20px",
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spin size="small" /> // Show spinner inside the button
            ) : (
              "Save Changes"
            )}

          </button>
        </div>,
      ]}
    >

      <div className="add-brand-section pt-2">

        {/* <spin spinning={loading}> */}

        <div className="row">
          <div className="col-lg-12 mb-3">
            <label className="add_brand_label">Brand Name</label>
            <Input
              placeholder="Enter Brand"
              className="form-control custom-placeholder"
              name="brandName"
              value={formData.brandName}
              onChange={handleInputChange}
            />
            {errors.brandName && <span className="error-message">{errors.brandName}</span>}
          </div>
          <div className="col-lg-12 mb-3">
            <div className="col">
              <label
                htmlFor="inputState"
                className="form-label text-align-left d-flex pb-1 add_brand_label"
              >
                Categories
              </label>
              <Select
                options={Categoryoptions}
                className="mt-2"
                placeholder="Select Category"
                styles={{ ...customStyles, menuPortal: document.body }}
                onChange={handleCategoryChange}
                value={selectedCategory}
                required
              />
              {errors.category && <span className="error-message">{errors.category}</span>}
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <label className="add_brand_label">Enter KG</label>
            <Input
              placeholder="Enter Weight"
              className="form-control custom-placeholder"
              name="totalKg"
              value={formData.totalKg}
              onChange={handleInputChange}
            />
            {errors.totalKg && <span className="error-message">{errors.totalKg}</span>}
          </div>
          <div className="col-lg-6 mb-3">
            <label className="add_brand_label">Availability</label>
            <div className="d-flex gap-3 mt-2">
              <div>
                <input
                  type="radio"
                  id="yes"
                  name="availability"
                  value="Yes"
                  checked={formData.availability === "Yes"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="yes" className="ms-2">
                  Yes
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="no"
                  name="availability"
                  value="No"
                  checked={formData.availability === "No"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="no" className="ms-2">
                  No
                </label>
              </div>
            </div>
            {errors.availability && <span className="error-message">{errors.availability}</span>}
          </div>
          <div className="col-lg-6 mb-3">
            <div>
              <div className="d-flex flex-column gap-1">
                <label className="add_brand_label">Upload Photos</label>
                <div className="add_image_divs mt-1">
                  <div className="add_image_icons">
                    <label htmlFor="uploadInput">
                      <FiUploadCloud className="add_image_upload_icon" />
                    </label>
                    <input
                      type="file"
                      id="uploadInput"
                      style={{ display: "none" }}
                      onChange={handleMainImageChange}
                    />
                    <AiOutlineDelete
                      className="add_image_delete_icon"
                      onClick={handleDeleteImage}
                    />
                  </div>
                  <div>
                    <img
                      src={mainImg ? URL.createObjectURL(mainImg) : product_4}
                      className="previewImage-brand"
                      alt="Preview"
                    />
                  </div>
                </div>
                {errors.image && <span className="error-message">{errors.image}</span>}

              </div>
            </div>
          </div>

        </div>

        {/* </spin> */}
      </div>

    </Modal>
  );
};

export default EditBrands;
